import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';

import ErrorIcon from '@mui/icons-material/Error';

import { Tooltip } from '@components/generic/Tooltip';

import { getCurrentUser, getUserType } from '@libs/getSharedVar';
import { removeAccessToken } from '@libs/jwt';

import { deleteEntity } from '@api/common/deleteEntity';

const DeleteAccountDialog = ({
    hasPaymentRequestsOrRevenues,
    handleToggleAccountDeletionDialog,
}: IDeleteAccountProps) => {
    const { t } = useTranslation();
    const { permissions, email } = getCurrentUser();
    const [emailVerification, setEmailVerification] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const isCorrectEmail = email === emailVerification;
    const isButtonDisabled = isLoading || !isCorrectEmail || permissions !== 'owner';
    const [isAccountDeleted, setIsAccountDeleted] = useState(false);
    const side = getUserType();

    const handleDeleteAccount = async () => {
        const response = await deleteEntity();
        setIsLoading(true);
        setError(null);
        if (response.status === 200) {
            setIsAccountDeleted(true);
        } else {
            const errorResponse = await response.json();
            setError(
                `${errorResponse.statusCode} ${errorResponse.error} : ${errorResponse.message}`,
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isAccountDeleted) {
            removeAccessToken(side);
            setTimeout(() => {
                window.location.assign('https://affilae.com/fr/logiciel-affiliation/');
            }, 3000);
        }
    }, [isAccountDeleted]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                !isLoading || (!isAccountDeleted && handleToggleAccountDeletionDialog());
            }}
            PaperProps={{
                'data-testid': 'deleteDialog',
            }}
        >
            {!isAccountDeleted ? (
                <>
                    <DialogTitle>{t('advertiser_form_dialog_delete_account_title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {side === 'advertiser' && (
                                <div>{t(side + '_form_dialog_delete_account_content')}</div>
                            )}

                            {side === 'publisher' && hasPaymentRequestsOrRevenues && (
                                <div>
                                    <div>{t(side + '_form_dialog_delete_account_content')}</div>

                                    <div>
                                        {t(
                                            side +
                                                '_form_dialog_delete_account_content_payments_and_earnings',
                                        )}
                                    </div>

                                    <div>
                                        {t(side + '_form_dialog_deleted_account_content_footer')}
                                    </div>
                                </div>
                            )}

                            {side === 'publisher' && !hasPaymentRequestsOrRevenues && (
                                <div>
                                    <div>{t(side + '_form_dialog_delete_account_content')}</div>

                                    <div>
                                        {t(side + '_form_dialog_deleted_account_content_footer')}
                                    </div>
                                </div>
                            )}
                        </DialogContentText>
                        <TextField
                            value={emailVerification}
                            style={{ minWidth: '300px' }}
                            label='Email'
                            error={!isCorrectEmail}
                            onChange={(event) => {
                                error && setError(null);
                                setEmailVerification(event.target.value);
                            }}
                            data-testid='emailField'
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isLoading}
                            color='quiet'
                            onClick={() => handleToggleAccountDeletionDialog()}
                        >
                            {t('generic_cancel')}
                        </Button>
                        <div
                            className='submitWrapper'
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginRight: '15px',
                                minWidth: '120px',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setIsLoading(true);
                                    handleDeleteAccount();
                                    setError(null);
                                }}
                                color='error'
                                variant='contained'
                                disabled={isButtonDisabled}
                            >
                                {t('generic_delete')}
                                {isLoading && (
                                    <CircularProgress size={15} style={{ marginLeft: '5px' }} />
                                )}
                            </Button>
                            {error && (
                                <Tooltip title={error}>
                                    <ErrorIcon color='error' fontSize='small' />
                                </Tooltip>
                            )}
                        </div>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent>
                        <DialogContentText>
                            {t(side + '_form_dialog_deleted_account_with_redirection')}
                        </DialogContentText>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};
export default DeleteAccountDialog;

interface IDeleteAccountProps {
    open: boolean;
    handleToggleAccountDeletionDialog: () => void;
    hasPaymentRequestsOrRevenues?: boolean;
}
