import {
    ADD_ADVERTISER_PRODUCT_IMAGE_FAILURE,
    ADD_ADVERTISER_PRODUCT_IMAGE_SUCCESS,
    ADVERTISER_PRODUCTS_LOADING,
    ADVERTISER_PRODUCTS_PRODUCTS_FILTER_CHANGE,
    CHECK_ADVERTISER_PRODUCTS_QUOTA_FAILURE,
    CHECK_ADVERTISER_PRODUCTS_QUOTA_SUCCESS,
    CLOSE_ALL_DIALOGS,
    CREATE_ADVERTISER_PRODUCT_FAILURE,
    CREATE_ADVERTISER_PRODUCT_SUCCESS,
    DELETE_ADVERTISER_PRODUCT_FAILURE,
    DELETE_ADVERTISER_PRODUCT_IMAGES_FAILURE,
    DELETE_ADVERTISER_PRODUCT_IMAGES_SUCCESS,
    DELETE_ADVERTISER_PRODUCT_SUCCESS,
    FETCH_ADVERTISER_PRODUCT_REQUESTS_FAILURE,
    FETCH_ADVERTISER_PRODUCT_REQUESTS_SUCCESS,
    FETCH_ADVERTISER_PRODUCTS_FAILURE,
    FETCH_ADVERTISER_PRODUCTS_SUCCESS,
    OPEN_ADD_PRODUCT_DIALOG,
    OPEN_DELETE_PRODUCT_DIALOG,
    OPEN_EDIT_PRODUCT_DIALOG,
    SOFT_DELETE_ADVERTISER_PRODUCT_IMAGES,
    UPDATE_ADVERTISER_PRODUCT_FAILURE,
    UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_FAILURE,
    UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_SUCCESS,
    UPDATE_ADVERTISER_PRODUCT_STATUS_FAILURE,
    UPDATE_ADVERTISER_PRODUCT_STATUS_SUCCESS,
    UPDATE_ADVERTISER_PRODUCT_SUCCESS,
} from '@actions/advertiserProducts';

const productFormDialogEmptyFields = {
    id: null,
    program: null,
    title: '',
    description: '',
    lang: null,
    url: '',
    images: [],
    category: null,
    price: '',
    deliveryTime: '',
    comment: '',
    statusAdmin: 'pending',
};

const productsEmptyQueryCriteria = {
    programs: [],
    status: '',
    search: '',
};

const productRequestsEmptyQueryCriteria = {
    programs: [],
    status: [],
    affiliateProfile: null,
    product: null,
    search: '',
};

const INITIAL_STATE = {
    loading: false,
    error: null,
    products: {
        data: null,
        queryCriteria: productsEmptyQueryCriteria,
        productsCount: null,
        productsLimit: null,
    },
    productRequests: {
        data: null,
        queryCriteria: productRequestsEmptyQueryCriteria,
    },
    productFormDialog: {
        isOpen: false,
        action: 'create', // can be create or update
        fields: productFormDialogEmptyFields,
        toDeleteOnSubmitImageIds: [],
        toDeleteOnCancelImageIds: [],
    },
    deleteProductDialog: {
        isOpen: false,
        product: null,
    },
    customStatusFilter: '',
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ADVERTISER_PRODUCTS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ADVERTISER_PRODUCTS_PRODUCTS_FILTER_CHANGE:
            return {
                ...state,
                customStatusFilter: action.payload,
            };

        case FETCH_ADVERTISER_PRODUCTS_SUCCESS:
        case UPDATE_ADVERTISER_PRODUCT_STATUS_SUCCESS:
        case UPDATE_ADVERTISER_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: {
                    ...state.products,
                    data: action.payload,
                },
            };
        case CREATE_ADVERTISER_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: {
                    ...state.products,
                    data: action.payload,
                    productsCount: state.products.productsCount + 1,
                },
            };
        case DELETE_ADVERTISER_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: {
                    ...state.products,
                    data: action.payload,
                    productsCount: state.products.productsCount - 1,
                },
            };
        case FETCH_ADVERTISER_PRODUCT_REQUESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                productRequests: {
                    ...state.productRequests,
                    data: action.payload,
                },
            };

        case UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                productRequests: {
                    ...state.productRequests,
                    data: action.payload,
                },
            };
        case CHECK_ADVERTISER_PRODUCTS_QUOTA_SUCCESS:
            return {
                ...state,
                loading: false,
                products: {
                    ...state.products,
                    productsCount: action.payload.productsCount,
                    productsLimit: action.payload.productsLimit,
                },
            };
        case ADD_ADVERTISER_PRODUCT_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                productFormDialog: {
                    ...state.productFormDialog,
                    fields: {
                        ...state.productFormDialog.fields,
                        images: action.payload,
                    },
                    toDeleteOnCancelImageIds: action.toDeleteOnCancelImageIds,
                },
            };
        case DELETE_ADVERTISER_PRODUCT_IMAGES_SUCCESS: // todo remove ?
            return {
                ...state,
                loading: false,
                productFormDialog: {
                    ...state.productFormDialog,
                    fields: {
                        ...state.productFormDialog.fields,
                    },
                },
            };

        case SOFT_DELETE_ADVERTISER_PRODUCT_IMAGES:
            return {
                ...state,
                loading: false,
                productFormDialog: {
                    ...state.productFormDialog,
                    fields: {
                        ...state.productFormDialog.fields,
                        images: action.payload,
                    },
                    toDeleteOnSubmitImageIds: action.toDeleteOnSubmitImageIds,
                },
            };
        case FETCH_ADVERTISER_PRODUCTS_FAILURE:
        case FETCH_ADVERTISER_PRODUCT_REQUESTS_FAILURE:
        case DELETE_ADVERTISER_PRODUCT_FAILURE:
        case UPDATE_ADVERTISER_PRODUCT_STATUS_FAILURE:
        case UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_FAILURE:
        case CHECK_ADVERTISER_PRODUCTS_QUOTA_FAILURE:
        case ADD_ADVERTISER_PRODUCT_IMAGE_FAILURE:
        case DELETE_ADVERTISER_PRODUCT_IMAGES_FAILURE:
        case CREATE_ADVERTISER_PRODUCT_FAILURE:
        case UPDATE_ADVERTISER_PRODUCT_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case OPEN_ADD_PRODUCT_DIALOG:
            return {
                ...state,
                productFormDialog: {
                    ...state.productFormDialog,
                    isOpen: true,
                    action: 'create',
                    toDeleteOnSubmitImageIds: [],
                    toDeleteOnCancelImageIds: [],
                },
            };

        case OPEN_EDIT_PRODUCT_DIALOG: {
            const initialProduct = action.product;
            return {
                ...state,
                productFormDialog: {
                    ...state.productFormDialog,
                    isOpen: true,
                    action: 'update',
                    fields: {
                        id: initialProduct.id,
                        title: initialProduct.title,
                        description: initialProduct.description,
                        lang: initialProduct.lang,
                        url: initialProduct.url,
                        program: initialProduct.program.id,
                        images: initialProduct.images,
                        category: initialProduct.category,
                        price: (initialProduct.price / 100).toString(),
                        deliveryTime: initialProduct.deliveryTime.toString(),
                        comment: initialProduct.comment ? initialProduct.comment : '',
                        statusAdmin: initialProduct.statusAdmin,
                    },
                    toDeleteOnSubmitImageIds: [],
                    toDeleteOnCancelImageIds: [],
                },
            };
        }
        case OPEN_DELETE_PRODUCT_DIALOG:
            return {
                ...state,
                deleteProductDialog: {
                    isOpen: true,
                    product: action.product,
                },
            };
        case CLOSE_ALL_DIALOGS:
            return {
                ...state,
                productFormDialog: {
                    ...state.productFormDialog,
                    isOpen: false,
                    fields: productFormDialogEmptyFields,
                    toDeleteOnSubmitImageIds: [],
                    toDeleteOnCancelImageIds: [],
                },
                deleteProductDialog: {
                    isOpen: false,
                    product: null,
                },
            };
        default:
            break;
    }

    return state;
}
