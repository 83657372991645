import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { matchIsValidTel } from 'mui-tel-input';

import { LoadingButton } from '@mui/lab';

import { Button, Checkbox, Collapse, FormControlLabel } from '@mui/material';
import Paper from '@mui/material/Paper';

import DeleteAccountDialog from '@components/common/Billings/DeleteAccountDialog';
import { FormPageLayout, FormPageTitle } from '@components/generic/Form';
import PageTabTitle from '@components/generic/PageTabTitle/PageTabTitle';

import { BasicFieldState, TextFieldState, useField, useTextField } from '@hooks/useField';
import { useOldAppState } from '@hooks/useOldAppState';
import { usePromise } from '@hooks/usePromise';

import { getCurrentUser } from '@libs/getSharedVar';
import { MongoId } from '@libs/types';
import { ComplexValidator, isEmpty, isNotEmpty } from '@libs/validators';

import { updateBillingDetails as updateBillingDetailsApi } from '@api/advertiser/billings/updateDetails';
import { Advertiser, AdvertiserType } from '@api/advertiser/fetchCurrentAdvertiserData';
import { detectCountry } from '@api/common/detectCountry';
import { User } from '@api/user/types';

import { DetailsForm } from './DetailsForm';

export type BillingDetails = {
    companyName: TextFieldState;
    type: BasicFieldState<AdvertiserType>;
    country: BasicFieldState<string>;
    companyId: TextFieldState;
    companyVat: TextFieldState;
    isVatFree: BasicFieldState<boolean>;
    companyPhone: TextFieldState;
    billingUsers: BasicFieldState<MongoId[]>;
    address: TextFieldState;
    zipcode: TextFieldState;
    city: TextFieldState;
};

type AdvertiserBillingDetails = {
    default: BillingDetails;
    forAffiliate: BillingDetails;
};

export default function Form() {
    const { t } = useTranslation();
    const advertiser = useOldAppState().state.advertiser!;
    const isSubscribed = advertiserHaveBeenSubscribed(advertiser);
    const [useDifferentDetailsForAffiliates, setUseDifferentDetailsForAffiliates] =
        useState<boolean>(advertiser.affiliatesBilling != null);
    const [deleteDialogOpened, setDeleteDialogOpened] = useState<boolean>(false);

    const oldApp = useOldAppState().app;
    const [hasTryToSubmit, setHasTryToSubmit] = useState<boolean>(false);
    const [createBillingDetailsLoading, createBillingDetails] = usePromise(
        updateBillingDetailsApi,
        {
            onSuccess(advertiser) {
                setHasTryToSubmit(false);
                oldApp.setState({ advertiser });
                addFlash('success', t('generic_changes_saved'));
            },
            onFailure(error) {
                window.addFlash('error', error.message);
                console.error(error);
            },
        },
    );

    const user: User = getCurrentUser();
    const isOwner = user.permissions === 'owner';

    useEffect(() => {
        const isFirstAttempt = hasAdvertiserCompletedDetails(advertiser) === false;
        if (isFirstAttempt) {
            detectCountry()
                .catch(() => 'FR')
                .then((country) => {
                    fields.default.country.setValue(country);
                    fields.forAffiliate.country.setValue(country);
                });
        }
    }, []);

    async function handleSubmit() {
        setHasTryToSubmit(true);
        const bodyObj = {
            companyName: fields.default.companyName.value,
            type: fields.default.type.value,
            country: fields.default.country.value,
            isVatFree: fields.default.isVatFree.value,
            companyPhone: fields.default.companyPhone.value,
            billingUsers: fields.default.billingUsers.value,
            address: fields.default.address.value,
            zipcode: fields.default.zipcode.value,
            city: fields.default.city.value,
            differentAffiliatesBilling: useDifferentDetailsForAffiliates,
        };
        if (useDifferentDetailsForAffiliates) {
            bodyObj.billingInfoType = fields.forAffiliate.type.value;
            bodyObj.billingInfoCompanyName = fields.forAffiliate.companyName.value;
            bodyObj.billingInfoCompanyPhone = fields.forAffiliate.companyPhone.value;
            bodyObj.billingInfoBillingUsers = fields.forAffiliate.billingUsers.value;
            bodyObj.billingInfoAddress = fields.forAffiliate.address.value;
            bodyObj.billingInfoZipcode = fields.forAffiliate.zipcode.value;
            bodyObj.billingInfoCity = fields.forAffiliate.city.value;
            bodyObj.billingInfoCountry = fields.forAffiliate.country.value;
            bodyObj.billingInfoIsVatFree = fields.forAffiliate.isVatFree.value;
        }

        if (fields.default.type.value === 'company') {
            bodyObj.companyId = fields.default.companyId.value;
        }

        if (fields.default.type.value === 'company') {
            bodyObj.companyVat = fields.default.companyVat.value;
        }

        if (fields.default.type.value === 'company' && fields.default.isVatFree.value === false) {
            bodyObj.companyVat = fields.default.companyVat.value;
        }

        if (useDifferentDetailsForAffiliates && fields.forAffiliate.type.value === 'company') {
            bodyObj.billingInfoCompanyId = fields.forAffiliate.companyId?.value;
        }

        if (useDifferentDetailsForAffiliates && fields.forAffiliate.type?.value === 'company') {
            bodyObj.billingInfoCompanyVat = fields.forAffiliate.companyVat?.value;
        }

        if (
            useDifferentDetailsForAffiliates &&
            fields.forAffiliate.type?.value === 'company' &&
            fields.forAffiliate.isVatFree?.value === false
        ) {
            bodyObj.billingInfoCompanyVat = fields.forAffiliate.companyVat?.value;
        }

        if (!useDifferentDetailsForAffiliates && isFormValid()) {
            await createBillingDetails(bodyObj);
        } else if (useDifferentDetailsForAffiliates && isFormValid() && isCustomFormValid()) {
            await createBillingDetails(bodyObj);
        } else {
            return;
        }
    }

    const handleToggleAccountDeletionDialog = () => {
        setDeleteDialogOpened(!deleteDialogOpened);
    };

    const companyNameValidator: ComplexValidator<string> = (companyName) => {
        if (isEmpty(companyName)) {
            return {
                isValid: false,
                helperText: t('publisher_form_companyName_helpertext'),
            };
        } else {
            return { isValid: true };
        }
    };

    const companyNameField = useTextField(advertiser.company ?? '', {
        validator: companyNameValidator,
    });

    const customBillingCompanyNameField = useTextField(
        advertiser.affiliatesBilling?.company ?? '',
        {
            validator: companyNameValidator,
        },
    );

    const companyIdValidator: ComplexValidator<string> = (companyId) => {
        if (isEmpty(companyId)) {
            return {
                isValid: false,
                helperText: t('publisher_form_companyId_helpertext'),
            };
        } else {
            return { isValid: true };
        }
    };

    const companyIdField = useTextField(advertiser.companyId ?? '', {
        validator: companyIdValidator,
    });

    const customBillingCompanyIdField = useTextField(
        advertiser.affiliatesBilling?.companyId ?? '',
        {
            validator: companyIdValidator,
        },
    );

    const companyVatValidator: ComplexValidator<string> = (companyVat) => {
        if (isEmpty(companyVat)) {
            return {
                isValid: false,
                helperText: t('advertiser_form_companyVAT_helpertext'),
            };
        } else {
            return { isValid: true };
        }
    };

    const companyVatField = useTextField(advertiser.companyVat ?? '', {
        validator: companyVatValidator,
    });

    const customBillingCompanyVatField = useTextField(
        advertiser.affiliatesBilling?.companyVat ?? '',
        {
            validator: companyVatValidator,
        },
    );

    const billingUsersField = useField(advertiser.billingUsers ?? [], { validator: isNotEmpty });
    const customBillingBillingUsersField = useField(
        advertiser.affiliatesBilling?.billingUsers ?? [],
        {
            validator: isNotEmpty,
        },
    );

    const companyPhoneValidator: ComplexValidator<string> = (companyPhone) => {
        if (isEmpty(companyPhone)) {
            return {
                isValid: false,
                helperText: t('publisher_form_phone_empty_helpertext'),
            };
        } else if (!matchIsValidTel(companyPhone)) {
            return {
                isValid: false,
                helperText: t('publisher_form_not_phone_helpertext'),
            };
        } else {
            return { isValid: true };
        }
    };

    const companyPhoneField = useTextField(advertiser.phone ?? '', {
        validator: companyPhoneValidator,
    });

    const customBillingCompanyPhoneField = useTextField(advertiser.affiliatesBilling?.phone ?? '', {
        validator: companyPhoneValidator,
    });

    const addressValidator: ComplexValidator<string> = (address) => {
        if (isEmpty(address)) {
            return {
                isValid: false,
                helperText: t('publisher_form_address_helpertext'),
            };
        } else {
            return { isValid: true };
        }
    };

    const addressField = useTextField(advertiser.address ?? '', {
        validator: addressValidator,
    });

    const customBillingAddressField = useTextField(advertiser.affiliatesBilling?.address ?? '', {
        validator: addressValidator,
    });

    const zipCodeValidator: ComplexValidator<string> = (zipcode) => {
        if (isEmpty(zipcode)) {
            return {
                isValid: false,
                helperText: t('publisher_form_zipcode_helpertext'),
            };
        } else {
            return { isValid: true };
        }
    };

    const zipCodeField = useTextField(advertiser.zipcode ?? '', {
        validator: zipCodeValidator,
    });

    const customBillingZipcodeField = useTextField(advertiser.affiliatesBilling?.zipcode ?? '', {
        validator: zipCodeValidator,
    });

    const cityValidator: ComplexValidator<string> = (city) => {
        if (isEmpty(city)) {
            return {
                isValid: false,
                helperText: t('publisher_form_city_helpertext'),
            };
        } else {
            return { isValid: true };
        }
    };

    const cityField = useTextField(advertiser.city ?? '', {
        validator: cityValidator,
    });

    const customBillingCityField = useTextField(advertiser.affiliatesBilling?.city ?? '', {
        validator: cityValidator,
    });

    const entityTypeField = useField(advertiser.type !== '' ? advertiser.type : 'company', {
        validator: isNotEmpty,
    });
    const customBillingTypeField = useField(advertiser.affiliatesBilling?.type ?? 'company', {
        validator: isNotEmpty,
    });

    const countryField = useTextField(advertiser.country ?? 'FR');

    const customBillingCountryField = useTextField(advertiser.affiliatesBilling?.country ?? 'FR');

    const isVatFreeField = useField(advertiser.isVatFree ?? false, { validator: isNotEmpty });
    const customBillingIsVatFreeField = useField(advertiser.affiliatesBilling?.isVatFree ?? false, {
        validator: isNotEmpty,
    });

    const isFormValid = () => {
        return (
            billingUsersField.isValid &&
            countryField.isValid &&
            addressField.isValid &&
            zipCodeField.isValid &&
            cityField.isValid &&
            companyPhoneField.isValid &&
            ((entityTypeField.value === 'company' &&
                companyNameField.isValid &&
                companyIdField.isValid &&
                ((isVatFreeField.value === false && companyVatField.isValid) ||
                    isVatFreeField.value === true)) ||
                entityTypeField.value === 'association')
        );
    };

    const isCustomFormValid = () => {
        return (
            customBillingBillingUsersField.isValid &&
            customBillingAddressField.isValid &&
            customBillingZipcodeField.isValid &&
            customBillingCityField.isValid &&
            customBillingCountryField.isValid &&
            customBillingCompanyPhoneField.isValid &&
            ((customBillingTypeField.value === 'company' &&
                customBillingCompanyNameField.isValid &&
                customBillingCompanyIdField.isValid &&
                ((customBillingIsVatFreeField.value === false &&
                    customBillingCompanyVatField.isValid) ||
                    customBillingIsVatFreeField.value === true)) ||
                customBillingTypeField.value === 'association')
        );
    };

    const fields: AdvertiserBillingDetails = {
        default: {
            companyName: companyNameField,
            type: entityTypeField,
            country: countryField,
            companyId: companyIdField,
            companyVat: companyVatField,
            isVatFree: isVatFreeField,
            companyPhone: companyPhoneField,
            billingUsers: billingUsersField,
            address: addressField,
            zipcode: zipCodeField,
            city: cityField,
        },
        forAffiliate: {
            companyName: customBillingCompanyNameField,
            type: customBillingTypeField,
            country: customBillingCountryField,
            companyId: customBillingCompanyIdField,
            companyVat: customBillingCompanyVatField,
            isVatFree: customBillingIsVatFreeField,
            companyPhone: customBillingCompanyPhoneField,
            billingUsers: customBillingBillingUsersField,
            address: customBillingAddressField,
            zipcode: customBillingZipcodeField,
            city: customBillingCityField,
        },
    };

    return (
        <FormPageLayout>
            <PageTabTitle>{t('helmet_title_billing_details')}</PageTabTitle>
            <FormPageTitle>{t('advertiser_details_tab_title')}</FormPageTitle>
            <div className='detailsConfigForm'>
                {deleteDialogOpened && (
                    <DeleteAccountDialog
                        open={deleteDialogOpened}
                        handleToggleAccountDeletionDialog={handleToggleAccountDeletionDialog}
                    />
                )}

                <Paper
                    sx={{
                        marginTop: '32px',
                        padding: '20px 30px',
                    }}
                >
                    <DetailsForm
                        fields={fields.default}
                        isCustomBilling={false}
                        isSubscribed={isSubscribed}
                        hasTryToSubmit={hasTryToSubmit}
                    />
                    <div className='affiliateDetails' data-testid='affiliateDetails'>
                        <FormControlLabel
                            label={t('advertiser_form_different_billing_info')}
                            control={
                                <Checkbox
                                    checked={useDifferentDetailsForAffiliates}
                                    onChange={(event) =>
                                        setUseDifferentDetailsForAffiliates(event.target.checked)
                                    }
                                    value='useDifferentDetailsForAffiliates'
                                    id='useCustomBillingInfo'
                                />
                            }
                        />

                        <Collapse in={useDifferentDetailsForAffiliates}>
                            <DetailsForm
                                fields={fields.forAffiliate}
                                isCustomBilling={true}
                                isSubscribed={isSubscribed}
                                hasTryToSubmit={hasTryToSubmit}
                            />
                        </Collapse>
                    </div>

                    <div className='actionButtons'>
                        <div className='submitBtnLayout'>
                            <ValidateBtn
                                disabled={
                                    !useDifferentDetailsForAffiliates
                                        ? !isFormValid() && hasTryToSubmit
                                        : !(isCustomFormValid() && isFormValid()) && hasTryToSubmit
                                }
                                onClick={() => handleSubmit()}
                                loadingStatus={createBillingDetailsLoading.status === 'loading'}
                            />
                        </div>
                        <div>
                            {isOwner && (
                                <Button
                                    color='error'
                                    disabled={!isOwner}
                                    onClick={handleToggleAccountDeletionDialog}
                                >
                                    {t('advertiser_form_dialog_delete_account_button')}
                                </Button>
                            )}
                        </div>
                    </div>
                </Paper>
            </div>
        </FormPageLayout>
    );
}

const ValidateBtn = ({ disabled, onClick, loadingStatus }: IValidateButton) => {
    const { t } = useTranslation();
    return (
        <LoadingButton
            onClick={onClick}
            disabled={disabled}
            className='submitBtn'
            variant='contained'
            color='primary'
            loading={loadingStatus}
            id='submit'
        >
            {t('affiliate_form_button_edit')}
        </LoadingButton>
    );
};

interface IValidateButton {
    disabled?: boolean;
    onClick: () => void;
    loadingStatus: any;
}

function advertiserHaveBeenSubscribed(advertiser: Advertiser) {
    const isSubscribed = ['subscribed', 'subscribed_ended', 'cancelled'].includes(
        advertiser.status,
    );
    return isSubscribed;
}

export function hasAdvertiserCompletedDetails(advertiser: Advertiser) {
    return _.isEmpty(advertiser.company) === false;
}
