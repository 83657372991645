import {
    ADD_INVOICE_SUCCESS,
    CLOSE_ALL_DIALOGS,
    DELETE_INVOICE_SUCCESS,
    FETCH_PUBLISHER_EARNINGS_DATA,
    FETCH_PUBLISHER_EARNINGS_DATA_FAILURE,
    FETCH_PUBLISHER_EARNINGS_DATA_SUCCESS,
    // INIT_PROGRAM, UPDATE_ADVERTISER_PAYMENTS_CRITERIA, UPDATE_ADVERTISER_EARNINGS_CRITERIA,
    FETCH_PUBLISHER_PAYMENT_REQUEST,
    FETCH_PUBLISHER_PAYMENT_REQUEST_FAILURE,
    FETCH_PUBLISHER_PAYMENT_REQUEST_SUCCESS,
    FETCH_PUBLISHER_PAYMENTS_DATA,
    FETCH_PUBLISHER_PAYMENTS_DATA_FAILURE,
    FETCH_PUBLISHER_PAYMENTS_DATA_SUCCESS,
    INIT_AFFILIATE_PROFILE,
    OPEN_DELETE_CUSTOM_INVOICE_DIALOG,
    PUBLISHER_PAYMENT_EARNINGS_LOADING,
    PUBLISHER_PAYMENT_LOADING,
} from '@actions/publisherPayments';

import { PeriodRef } from '@components/generic/DateRangePicker';

import { IsoDate } from '@libs/types';

import { PaymentRequest } from '@api/advertiser/paymentRequests/info';

// Initial query is for the 30 last days
const initialFrom = new Date();
initialFrom.setDate(initialFrom.getDate() - 30);
initialFrom.setHours(0, 0, 0, 0);

const initialTo = new Date();
initialTo.setHours(23, 59, 59, 999);

export type PublisherPaymentState = {
    affiliateProfile: unknown | null;
    isPublisherVerified: boolean;
    isAdvertiserVerified: boolean;
    payments: {
        loading: boolean;
        data: Record<string, unknown>;
        kpis: {
            _id: {
                status: string;
                currency: string;
            };
            amount: number;
        }[];
        error: unknown | null;
        queryCriteria: {
            currency: string;
            dateRange: PeriodRef;
            from: IsoDate;
            to: IsoDate;
            status: '' | 'pending' | 'refused' | 'accepted' | 'paid' | 'scheduled';
            affiliateProfile: string;
            partnership: string;
            search: string;
        };
    };
    earnings: {
        loading: boolean;
        data: null | unknown[];
        error: unknown | null;
        queryCriteria: {
            currency: string;
            dateRange: PeriodRef;
            from: IsoDate;
            to: IsoDate;
            status: '' | 'active' | 'inactive';
            affiliateProfile: string;
            partnership: string;
            search: string;
        };
    };
    paymentRequestDetails: PaymentRequest | null;
    statusList: ['pending', 'accepted', 'scheduled', 'refused', 'paid'];
    deleteCustomInvoiceDialog: {
        isOpen: boolean;
        paymentRequestId: unknown | null;
    };
    advertiserNotVerifiedDialog: {
        isOpen: boolean;
        missingFields: unknown[];
    };
};

const INITIAL_STATE: PublisherPaymentState = {
    affiliateProfile: null,
    isPublisherVerified: false,
    isAdvertiserVerified: true,
    payments: {
        loading: false,
        data: {},
        kpis: [],
        error: null,
        queryCriteria: {
            currency: '',
            dateRange: 'last_4_weeks',
            status: '', // can be 'pending', 'refused', 'accepted', 'paid', 'scheduled'"
            affiliateProfile: '',
            partnership: '',
            search: '',
        },
    },
    earnings: {
        loading: false,
        data: null,
        error: null,
        queryCriteria: {
            currency: '',
            dateRange: 'last_4_weeks',
            status: '', // can be 'active' or 'inactive'
            affiliateProfile: '',
            partnership: '',
            search: '',
        },
    },
    paymentRequestDetails: null,
    statusList: ['pending', 'accepted', 'scheduled', 'refused', 'paid'],
    deleteCustomInvoiceDialog: {
        isOpen: false,
        paymentRequestId: null,
    },
    advertiserNotVerifiedDialog: {
        isOpen: false,
        missingFields: [],
    },
};

export default function (state = INITIAL_STATE, action): PublisherPaymentState {
    let error;

    switch (action.type) {
        case INIT_AFFILIATE_PROFILE: {
            return {
                ...state,
                affiliateProfile: action.affiliateProfile,
            };
        }
        case PUBLISHER_PAYMENT_LOADING:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loading: true,
                },
            };
        case PUBLISHER_PAYMENT_EARNINGS_LOADING:
            return {
                ...state,
                earnings: {
                    ...state.earnings,
                    loading: true,
                },
            };
        // case UPDATE_ADVERTISER_PAYMENTS_CRITERIA:
        //     return {
        //         ...state,
        //         payments: {
        //             ...state.payments,
        //             queryCriteria: action.queryCriteria,
        //         }
        //     }
        // case UPDATE_ADVERTISER_EARNINGS_CRITERIA:
        //     return {
        //         ...state,
        //         earnings: {
        //             ...state.earnings,
        //             queryCriteria: action.queryCriteria,
        //         }
        //     }
        case FETCH_PUBLISHER_PAYMENTS_DATA:
            return {
                ...state,
                payments: {
                    ...state.payments,
                },
            };
        case FETCH_PUBLISHER_PAYMENTS_DATA_SUCCESS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loading: false,
                    data: action.payload.paymentRequests,
                    kpis: action.payload.kpis,
                    error: error,
                },
            };
        case FETCH_PUBLISHER_PAYMENTS_DATA_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loading: false,
                    data: {},
                    kpis: [],
                    error: error,
                },
            };
        case FETCH_PUBLISHER_PAYMENT_REQUEST:
            return {
                ...state,
            };
        case FETCH_PUBLISHER_PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                paymentRequestDetails: action.payload,
                payments: {
                    ...state.payments,
                    loading: false,
                },
            };
        case FETCH_PUBLISHER_PAYMENT_REQUEST_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loading: false,
                    paymentRequestDetails: null,
                    error: error,
                },
            };
        case FETCH_PUBLISHER_EARNINGS_DATA:
            return {
                ...state,
                earnings: {
                    ...state.earnings,
                },
            };
        case FETCH_PUBLISHER_EARNINGS_DATA_SUCCESS:
            return {
                ...state,
                earnings: {
                    ...state.earnings,
                    loading: false,
                    data: action.payload,
                    error: error,
                },
            };
        case FETCH_PUBLISHER_EARNINGS_DATA_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                earnings: {
                    ...state.earnings,
                    loading: false,
                    data: null,
                    error: error,
                },
            };
        case DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    data: action.payload,
                },
                deleteCustomInvoiceDialog: {
                    isOpen: false,
                    paymentRequestId: null,
                },
            };
        case ADD_INVOICE_SUCCESS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    data: action.payload,
                },
            };

        case OPEN_DELETE_CUSTOM_INVOICE_DIALOG:
            return {
                ...state,
                deleteCustomInvoiceDialog: {
                    isOpen: true,
                    paymentRequestId: action.paymentRequestId,
                },
            };

        case CLOSE_ALL_DIALOGS:
            return {
                ...state,
                deleteCustomInvoiceDialog: {
                    isOpen: false,
                    paymentRequestId: null,
                },
                advertiserNotVerifiedDialog: {
                    isOpen: false,
                    missingFields: [],
                },
            };
        default:
            break;
    }

    return state;
}
