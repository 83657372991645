import { useCallback, useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import type { CookieSetOptions } from 'universal-cookie';

import { getUserTypeFromUrl, UserType, userTypes } from '@libs/getSharedVar';

import { MongoId, Timestamp } from './types';

export const cookies = new Cookies();

type JwtEventListener = () => void;
const _jwtEventListeners: Array<JwtEventListener> = [];

export function addJwtEventListener(callback: JwtEventListener) {
    _jwtEventListeners.push(callback);
}

export function removeJwtEventListener(callback: JwtEventListener) {
    const index = _jwtEventListeners.indexOf(callback);
    if (index >= 0) {
        _jwtEventListeners.splice(index, 1);
    }
}

export function getJwt(): string {
    const userType = getUserTypeFromUrl();
    return userType != null ? getJwtForUserType(userType)! : getAnyJwt();
}

function getAnyJwt(): string {
    const userType = getUserTypeFromAccessToken();
    if (userType != null) {
        return getJwtForUserType(userType)!;
    } else {
        throw new Error('No jwt');
    }
}

export function getUserTypeFromAccessToken(): UserType | undefined {
    const tokens = userTypes.map(
        (type) => cookies.get(getJwtCookieKey(type)) as string | undefined,
    );
    const index = tokens.findIndex((t) => t != null);
    if (index !== -1) {
        return userTypes[index];
    } else {
        return undefined;
    }
}

export function getUserTypeFromRefreshToken(): UserType | undefined {
    const tokens = userTypes.map(
        (type) => cookies.get(getRefreshJwtCookieKey(type)) as string | undefined,
    );
    const index = tokens.findIndex((t) => t != null);
    if (index !== -1) {
        return userTypes[index];
    } else {
        return undefined;
    }
}

export function getUserTypeFromTokens(): UserType | undefined {
    return getUserTypeFromAccessToken() ?? getUserTypeFromRefreshToken();
}

export function getJwtForUserType(userType: UserType): string | undefined {
    if (userType !== 'admin' && getJwtForUserType('admin') != null) {
        const token = sessionStorage.getItem(getJwtCookieKey(userType));
        if (token != null) return token;
    }
    return cookies.get(getJwtCookieKey(userType));
}

export function getRefreshToken(type: UserType): string | undefined {
    return cookies.get(getRefreshJwtCookieKey(type));
}

export function getJwtAdmin(): string {
    return getJwtForUserType('admin')!;
}

export function storeJwt(token: string, userType: UserType): void {
    if (userType !== 'admin' && getJwtForUserType('admin') != null) {
        sessionStorage.setItem(getJwtCookieKey(userType), token);
    }
    const tokenPayload = jwtDecode<AccessTokenPayload | AdminAccessTokenPayload>(token);
    const cookieOption: CookieSetOptions = {
        ...commonCookieOption,
        expires: new Date(tokenPayload.exp * 1000),
    };
    cookies.set(getJwtCookieKey(userType), token, cookieOption);
    _jwtEventListeners.forEach((fn) => fn());
}

export function storeRefreshToken(token: string, userType: UserType): void {
    const tokenPayload = jwtDecode<RefreshTokenPayload>(token);
    const cookieOption = {
        ...commonCookieOption,
        expires: new Date(tokenPayload.exp * 1000),
    };
    cookies.set(getRefreshJwtCookieKey(userType), token, cookieOption);
}

export function removeAllJwt() {
    userTypes.forEach((type) => sessionStorage.removeItem(getJwtCookieKey(type)));
    userTypes.forEach((type) => cookies.remove(getJwtCookieKey(type), commonCookieOption));
    userTypes.forEach((type) => cookies.remove(getRefreshJwtCookieKey(type), commonCookieOption));
    _jwtEventListeners.forEach((fn) => fn());
}

export function removeJwt(userType: Exclude<UserType, 'admin'>) {
    removeAccessToken(userType);
    cookies.remove(getRefreshJwtCookieKey(userType), commonCookieOption);
}

export function removeAccessToken(userType: UserType) {
    if (userType !== 'admin' && getJwtForUserType('admin') != null) {
        sessionStorage.removeItem(getJwtCookieKey(userType));
    }
    cookies.remove(getJwtCookieKey(userType), commonCookieOption);
    _jwtEventListeners.forEach((fn) => fn());
}

export function removeRefreshToken(userType: UserType) {
    cookies.remove(getRefreshJwtCookieKey(userType), commonCookieOption);
}

const isIp = (domain: string) => /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(domain);

/**
 * Compute the domain used to store the jwt cookie
 * we have to return a domain shared by the different affilae services
 *
 * @example affilae.com -> affilae.com
 * @example preprod.affilae.com -> .affilae.com
 * @example 35.205.236.25 -> 35.205.236.25
 */
export function getJwtCookieDomain(domain = location.hostname): string {
    if (isIp(domain)) {
        return domain;
    }

    const domainFragments = domain.split('.');
    if (domainFragments.length <= 2) {
        return domain;
    } else {
        return '.' + getParentDomain(domain);
    }
}

function getParentDomain(domain: string): string {
    const domainFragments = domain.split('.');
    if (domainFragments.length <= 2) {
        throw new Error('Cannot get parent domain');
    }
    return domainFragments.slice(domainFragments.length - 2, domainFragments.length).join('.');
}

const commonCookieOption: CookieSetOptions = {
    domain: getJwtCookieDomain(),
    path: '/',
    secure: CONFIG.secureCookie === true,
    sameSite: 'strict',
};

function getJwtCookieKey(type: UserType): `token-${UserType}` | `token-${UserType}-${string}` {
    const prefix =
        CONFIG.cookieSuffix != null && CONFIG.cookieSuffix.trim() !== ''
            ? (`-${CONFIG.cookieSuffix}` as `-${string}`)
            : '';
    return `token-${type}${prefix}`;
}

function getRefreshJwtCookieKey(type: UserType) {
    const prefix =
        CONFIG.cookieSuffix != null && CONFIG.cookieSuffix.trim() !== ''
            ? `-${CONFIG.cookieSuffix}`
            : '';
    return `token-${type}-refresh${prefix}`;
}

export function willTokenExpiredSoon(token: string): boolean {
    const tokenPayload = jwtDecode<TokenPayload>(token);
    const duration = moment.duration(moment.unix(tokenPayload.exp).diff(moment()));
    return duration.asHours() < 4;
}

export function hasTokenExpired(token: string): boolean {
    const tokenPayload = jwtDecode<TokenPayload>(token);
    return moment().isAfter(moment.unix(tokenPayload.exp));
}

export function getCanLogin(): boolean {
    const userType = getUserType();
    if (userType == null) {
        return false;
    } else {
        const accessToken = getJwtForUserType(userType);
        const refreshToken = getRefreshToken(userType);
        return accessToken != null || refreshToken != null;
    }
}

export function removeOldTokens() {
    const cookies = new Cookies();
    const tokens = userTypes.map(
        (type) => cookies.get(getJwtCookieKey(type)) as string | undefined,
    );
    tokens.forEach((token, index) => {
        const userType = userTypes[index];
        if (token != null && isOldAccessToken(token)) {
            removeAccessToken(userType);
        }
    });
}

const modernTokenTypes = ['access', 'accessAdmin', 'adminTakeover'];
function isOldAccessToken(token: string): boolean {
    const tokenPayload: any = jwtDecode(token);
    return !modernTokenTypes.includes(tokenPayload.tokenType);
}

export function useAccessToken(userType?: UserType): string | undefined {
    const forceRender = useForceRender();
    const getAccessToken = useCallback(() => {
        const finalUserType = userType ?? getUserType();
        if (finalUserType == null) {
            return undefined;
        }
        return getJwtForUserType(finalUserType);
    }, [userType]);

    const currentToken = getAccessToken();

    useEffect(() => {
        const handleJwtChange = () => {
            const newToken = getAccessToken();
            if (newToken !== currentToken) {
                forceRender();
            }
        };
        addJwtEventListener(handleJwtChange);
        () => removeJwtEventListener(handleJwtChange);
    }, [getAccessToken, forceRender, currentToken]);

    return currentToken;
}

/**
 * Return a function that when called force the component to render
 */
function useForceRender() {
    const [, updateState] = useState();
    const forceRender = useCallback(() => updateState({}), []);
    return forceRender;
}

export function getUserIdFromToken(token: string) {
    const tokenPayload = jwtDecode<TokenPayload>(token);
    return tokenPayload.uid;
}

export interface TokenPayload {
    /** user id */
    uid: MongoId;
    tokenType: string;
    /** Creation timestamp */
    iat: Timestamp;
    /** Expiration timestamp */
    exp: Timestamp;
}

export interface AccessTokenPayload extends TokenPayload {
    tokenType: 'access';
}
export interface RefreshTokenPayload extends TokenPayload {
    tokenType: 'refresh';
}
export interface AdminTokenPayload extends TokenPayload {
    tokenType: 'admin';
}

export interface AdminAccessTokenPayload extends TokenPayload {
    tokenType: 'accessAdmin';
    admin: MongoId;
}

export function getUserType() {
    return getUserTypeFromUrl() ?? getUserTypeFromAccessToken() ?? getUserTypeFromRefreshToken();
}
