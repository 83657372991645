import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { useOldAppState } from '@hooks/useOldAppState';

import { User } from '@api/user/types';

const propsIsMulti = {
    isMulti: true,
    isClearable: true,
    isSearchable: true,
};

interface Option {
    label: string;
    value: string;
    isDisabled?: boolean;
}

interface AffiliateProfile {
    id: string;
    name: string;
    [key: string]: any;
}

type AffiliateProfileFilter = string[] | ((ap: AffiliateProfile) => boolean);

interface profileSelectProps {
    value?: string | string[];
    onChange: ((value: string) => void) | ((value: string[]) => void);
    filterVisible?: AffiliateProfileFilter;
    filterEnabled?: AffiliateProfileFilter;
    isMulti?: boolean;
    autoSelect?: boolean; // on mount, if auto select is true and value is empty, select the first enabled option
    user?: User;
}

export function AffiliateProfileSelect({
    value,
    onChange,
    filterEnabled,
    isMulti = false,
    autoSelect = false,
    filterVisible,
    user,
}: profileSelectProps) {
    const { t } = useTranslation();
    const affiliateProfiles = useOldAppState().state.affiliateProfiles!;

    // array of customized profiles (permissions opened) for current edited user
    const selectedUserProfiles = useMemo(() => {
        const userProfilesId: string[] = [];
        if (user) {
            const userPermission = user && user.permissions;
            if (userPermission['affiliateProfiles']) {
                const userProfiles = userPermission.affiliateProfiles;
                return Object.keys(userProfiles);
            }
        }
        return userProfilesId;
    }, [user]);

    const options = useMemo(() => {
        let affiliateProfilesVisible = [...affiliateProfiles];
        if (filterVisible != null) {
            if (Array.isArray(filterVisible)) {
                affiliateProfilesVisible = affiliateProfiles.filter((profile) =>
                    filterVisible.includes(profile.id),
                );
            } else if (typeof filterVisible === 'function') {
                affiliateProfilesVisible = affiliateProfiles.filter(filterVisible);
            }
        }

        return affiliateProfilesVisible.map((profile) => {
            const isDisabled = (() => {
                if (Array.isArray(filterEnabled)) {
                    return !filterEnabled.includes(profile.id);
                } else if (typeof filterEnabled === 'function') {
                    return !filterEnabled(profile);
                } else {
                    return false;
                }
            })();

            if (selectedUserProfiles.includes(profile.id)) {
                return {
                    value: profile.id,
                    label: profile.name,
                    color: '#FE6E00',
                    isDisabled,
                };
            } else {
                return {
                    value: profile.id,
                    label: profile.name,
                    color: '#272727',
                    isDisabled,
                };
            }
        });
    }, [affiliateProfiles, filterEnabled, filterVisible, selectedUserProfiles]);

    const optionColorStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,

                backgroundColor: isDisabled
                    ? null
                    : isSelected
                      ? '#94b4ff'
                      : isFocused
                        ? '#deebff'
                        : null,

                color: data.color,
            };
        },

        singleValue: (styles, { data }) => {
            return {
                ...styles,
                color: data.color,
            };
        },
    };

    const selectedOptions = useMemo(() => {
        if (value == null) {
            return null;
        } else if (Array.isArray(value)) {
            return options.filter((o) => value.includes(o.value));
        } else {
            return options.find((o) => o.value === value);
        }
    }, [options, value]);

    useEffect(() => {
        if (autoSelect === true && options.length > 0) {
            const defaultProfile = affiliateProfiles.find((profile) =>
                selectedUserProfiles.includes(profile.id),
            );
            if (defaultProfile) {
                onChange(isMulti ? [defaultProfile.id] : defaultProfile.id);
            }
        }
    }, [affiliateProfiles, autoSelect, value, onChange, isMulti, selectedUserProfiles, options]);

    function handleChange(option: Option | Option[]) {
        if (Array.isArray(option)) {
            onChange(option.map((o) => o.value));
        } else {
            onChange(option.value);
        }
    }

    return (
        <Select
            options={options}
            value={selectedOptions}
            onChange={handleChange}
            styles={optionColorStyles}
            className='select-field-container'
            classNamePrefix='select-field'
            {...(isMulti ? propsIsMulti : null)}
            placeholder={t('products_select_affiliates_profiles')}
        />
    );
}
