import React, { lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ProductPageRestriction from '@pages/advertiser/ProductPageRestriction/ProductPageRestriction';
import PublisherSupportPage from '@pages/common/SupportPage/PublisherSupportPage';
import * as Sentry from '@sentry/react';

import { AffiliateProvider } from '@providers/AffiliateProvider';
//#endregion

import { LibsProvider } from '@providers/LibsProvider';
import { MfaLoader, RequireMfaDone, RequireMfaNotDone } from '@providers/MfaStateProvider';
import { ProgramProvider } from '@providers/ProgramProvider';
import { RefreshTokenHandler } from '@providers/RefreshTokenHandler';
import { SessionProvider } from '@providers/SessionProvider';
import { SSOProvider } from '@providers/SSOProvider';
import { TakeOverProvider } from '@providers/TakeOverProvider';

import { AppLoggedLayout } from '@components/common/AppLoggedLayout';
import { EmailDialogReminder } from '@components/common/EmailDialogReminder';
import { MfaDialogProvider } from '@components/generic/MultipleFactorAuth';
import MissingBankDetailsWarningBanner from '@components/nav/GlobalBanners/MissingBankDetailsWarningBanner/MissingBankDetailsWarningBanner';
import SubscriptionWarningBanner from '@components/nav/GlobalBanners/SubscriptionWarningBanner';
import { PageRoute } from '@components/nav/PageRoute';
import RequireAuth from '@components/nav/RequireAuth';
import RequireNotAuth from '@components/nav/RequireNotAuth';
import useOnBoardingRedirection, {
    onBoardingRoutingSubPath,
} from '@components/nav/useOnBoardingRedirection';

import { OldAppStateProvider, useOldAppState } from '@hooks/useOldAppState';

//#endregion

import {
    localeDefault,
    localesSupported,
    ParamTypes,
    ParamTypesLocale,
    userTypes,
} from '@libs/getSharedVar';
import { getUserTypeFromTokens, removeJwt, removeOldTokens } from '@libs/jwt';

const SideBar = lazy(() => import('./components/nav/SideBar'));

//#region import pages
// User
const UserEditDetails = lazy(() => import('@pages/user/EditDetailsPage'));
const PasswordRecoveryPage = lazy(() => import('@pages/user/PasswordRecoveryPage'));
const PasswordLostPage = lazy(() => import('@pages/user/PasswordLostPage'));
const LoginPage = lazy(() => import('@pages/user/LoginPage'));
const SignupFromInvitationPage = lazy(() => import('@pages/user/SignupFromInvitationPage'));

// public
const EmailValidationPage = lazy(() => import('@pages/public/EmailValidationPage'));

// Advertiser
const AdvertiserConversionsList = lazy(() => import('@pages/advertiser/ConversionsListPage'));
const AdvertiserReporting = lazy(() => import('@pages/advertiser/ReportingPage'));
const SignupPage = lazy(() => import('@pages/common/SignupPage'));
const AdvertiserSteps = lazy(() => import('@pages/advertiser/Steps'));
const AdvertiserProgramsCreate = lazy(
    () => import('@pages/advertiser/createProgramPage/CreateProgramPage'),
);
const AdvertiserVouchersList = lazy(() => import('@pages/common/vouchers'));
const AdvertiserLinkBuilder = lazy(() => import('@pages/advertiser/LinkBuilderPage'));
const AdvertiserAds = lazy(() => import('@pages/advertiser/AdsPage'));
const AdvertiserPayments = lazy(() => import('@pages/advertiser/PaymentsPage'));
const AdvertiserPartnerships = lazy(() => import('@pages/advertiser/PartnershipsPage'));
const AdvertiserPartnershipsEdit = lazy(() => import('@pages/advertiser/EditPartnershipPage'));
const AdvertiserBilling = lazy(() => import('@pages/advertiser/BillingPage'));
const AdvertiserProducts = lazy(() => import('@components/advertiser/products'));
const ProgramListPage = lazy(() => import('@pages/advertiser/ProgramListPage'));
const AdvertiserProgramsConfigurationPage = lazy(
    () => import('@pages/advertiser/ProgramsConfigurationPage'),
);
const AdvertiserFeedsPage = lazy(() => import('./pages/advertiser/FeedsPage'));
const DashboardPage = lazy(() => import('@pages/common/DashboardPage/DashboardPage'));
const AdvertiserSupportPage = lazy(() => import('@pages/common/SupportPage/AdvertiserSupportPage'));

// Publisher
const PublisherSteps = lazy(() => import('@pages/publisher/Steps'));
const PublisherConversionsList = lazy(
    () => import('@pages/publisher/ConversionsListPage/ConversionsListPage'),
);
const PublisherProfileAdd = lazy(() => import('@pages/publisher/CreateProfile'));
const PublisherProfileEdit = lazy(() => import('@pages/publisher/EditProfilePage'));
const PublisherDetailsPage = lazy(
    () => import('@pages/publisher/PublisherDetailsPage/PublisherDetailsPage'),
);
const PublisherReporting = lazy(() => import('@pages/publisher/ReportingPage'));
const PublisherVouchersList = lazy(() => import('@pages/common/vouchers/index'));
const PublisherLinkBuilder = lazy(() => import('@pages/publisher/LinkBuilderPage'));
const PublisherAds = lazy(() => import('@pages/publisher/AdsPage'));
const PublisherPayments = lazy(() => import('@pages/publisher/PaymentsPage'));
const PublisherPartnerships = lazy(() => import('@pages/publisher/PartnershipsPage'));
const PublisherProducts = lazy(() => import('@pages/publisher/ProductsPage'));
const AffiliateProfileListPage = lazy(() => import('@pages/publisher/AffiliateProfileListPage'));
const AffiliateProfileConfigurationPage = lazy(
    () => import('@pages/publisher/AffiliateProfileConfigurationPage'),
);
const PublisherFeedsPage = lazy(() => import('./pages/publisher/FeedsPage'));
const PublisherIntegrationPage = lazy(
    () => import('@pages/publisher/IntegrationPage/IntegrationPage'),
);

// Common
const PaymentRequestDetails = lazy(() => import('@pages/common/PaymentRequestDetailsPage'));
const MessagePage = lazy(() => import('@pages/common/MessagePage'));
const NewMessageAdvertiserPage = lazy(
    () => import('@pages/common/MessagePage/NewMessage/NewMessageAdvertiser'),
);
const NewMessagePublisherPage = lazy(
    () => import('@pages/common/MessagePage/NewMessage/NewMessagePublisher'),
);
const UserPage = lazy(() => import('@pages/common/UserPage'));
const ClicksPage = lazy(() => import('@pages/common/ClicksPage'));
const Page404 = lazy(() => import('@components/nav/redirectPages/Page404'));
const ApiKeyPage = lazy(() => import('@pages/common/ApiKeysPage'));
const MarketplacePage = lazy(() => import('@pages/common/MarketplacePage'));
const ConversionsExport = lazy(() => import('@pages/common/conversions_export'));

// Admin
const AdminProfilesAm = lazy(() => import('@pages/admin/AffiliateProfileAmPage'));
const AdminProgramsAm = lazy(() => import('@pages/admin/ProgramsAmPage'));
const AdminProductsAM = lazy(() => import('@pages/admin/Products'));
const AdminInvoices = lazy(() => import('@pages/admin/Invoices'));
const AdminPaymentRequests = lazy(() => import('@pages/admin/PaymentRequests'));
const AdminPartnerships = lazy(() => import('@pages/admin/Partnerships'));
const AdminEvents = lazy(() => import('@pages/admin/Events'));
const AdminAdvertiserPublisher = lazy(() => import('@pages/admin/adminAdvertiserPublisherPage'));
//#endregion

// Mobile
const MobilePasswordResetRedirect = lazy(
    () => import('@pages/mobileAppDeepLink/MobilePasswordResetRedirect'),
);

const mongoIdRegexStr = '[0-9a-fA-F]{24}';

export default function App() {
    useEffect(() => {
        removeOldTokens();
    }, []);
    const { i18n } = useTranslation();
    const locale = i18n.language;

    const pathLocale = `/:locale(${localesSupported.join('|')})`;
    const userTypeFromToken = getUserTypeFromTokens();

    return (
        <LibsProvider>
            <RefreshTokenHandler>
                <MfaLoader>
                    <Switch>
                        <Redirect exact from='/' to={localeDefault} />
                        <Route path={pathLocale}>
                            <Switch>
                                {userTypeFromToken != null && (
                                    <Redirect
                                        exact
                                        from={pathLocale}
                                        to={`/${locale}/${userTypeFromToken}`}
                                    />
                                )}

                                {/* public pages, accessible to logged or non logged users */}
                                <Route path={`${pathLocale}/email-validation`}>
                                    <EmailValidationPage />
                                </Route>

                                {/* todo: only routable when user not logged (token) or (mfa not confirmed)*/}
                                {/* tmp position */}
                                <Route path={`${pathLocale}/login`}>
                                    <RequireMfaNotDone>
                                        <LoginPage />
                                    </RequireMfaNotDone>
                                </Route>
                                <Route path={`${pathLocale}/signup-from-invitation`}>
                                    <RequireMfaNotDone>
                                        <SignupFromInvitationPage />
                                    </RequireMfaNotDone>
                                </Route>
                                <Route
                                    path={[
                                        `${pathLocale}/signup`,
                                        `${pathLocale}/publisher-signup`,
                                    ]}
                                >
                                    {/* todo include advertiser url parameters (currency, plans)*/}
                                    <SignupPage />
                                </Route>

                                <Route path={pathLocale + `/:side(${userTypes.join('|')})`}>
                                    <TakeOverProvider>
                                        <RequireAuth>
                                            <RequireMfaDone>
                                                <OldAppStateProvider>
                                                    <SessionProvider>
                                                        <SSOProvider>
                                                            <MfaDialogProvider>
                                                                <AppLogged />
                                                            </MfaDialogProvider>
                                                        </SSOProvider>
                                                    </SessionProvider>
                                                </OldAppStateProvider>
                                            </RequireMfaDone>
                                        </RequireAuth>
                                    </TakeOverProvider>
                                </Route>

                                <Route path={pathLocale}>
                                    <AppNotLogged />
                                </Route>
                            </Switch>
                        </Route>

                        <Route path='*'>
                            <h1>Locale not supported</h1>
                        </Route>
                    </Switch>
                </MfaLoader>
            </RefreshTokenHandler>
        </LibsProvider>
    );
}

function AppNotLogged() {
    const { path } = useRouteMatch<ParamTypesLocale>();

    return (
        <RequireNotAuth>
            <Switch>
                <Redirect from={path} exact to={`${path}/login`} />
                <Route path={`${path}/password-lost`}>
                    <PasswordLostPage />
                </Route>
                <Route path={`${path}/passwordRecovery`}>
                    <PasswordRecoveryPage />
                </Route>

                <Route path={`${path}/mobile/resetRedirect`}>
                    <MobilePasswordResetRedirect />
                </Route>
                <Route path={path + '/*'}>
                    <Page404 variant='public' />
                </Route>
            </Switch>
        </RequireNotAuth>
    );
}

function AppLogged() {
    const { app } = useOldAppState();
    const { path, params } = useRouteMatch<ParamTypes>();
    const { side } = params;
    const advertiser = app.state.advertiser;

    useEffect(() => {
        Sentry.setTag('side', side);
        return () => {
            Sentry.setTag('side', undefined);
        };
    }, [side]);

    // clean jwt
    useEffect(() => {
        // Normally it should not happen that a user has multiple jwt at the same time (except admin)
        // because we clean them on logout
        // but we clean them here just in case
        if (side === 'admin') {
            removeJwt('publisher');
            removeJwt('advertiser');
        }
        if (side === 'advertiser') {
            removeJwt('publisher');
            // we don't remove admin token because of potential admin take over
        }
        if (side === 'publisher') {
            removeJwt('advertiser');
            // we don't remove admin token because of potential admin take over
        }
    }, [side]);

    const onBoardingRedirectionHappened = useOnBoardingRedirection();
    if (onBoardingRedirectionHappened) {
        return null;
        // we don't want to display anything while redirection is
        // happening to avoid flickering.
        // We could also display a loader but it's not worth it for just some milliseconds
    }

    const pathProgram = path + `/:programId(${mongoIdRegexStr})`;
    const pathAffiliate = path + `/:affiliateProfileId(${mongoIdRegexStr})`;

    return (
        <AppLoggedLayout
            sideBar={<SideBar />}
            globalModals={
                <>
                    <EmailDialogReminder />
                </>
            }
            globalBanners={
                <>
                    <SubscriptionWarningBanner />
                    <MissingBankDetailsWarningBanner />
                </>
            }
            page={
                <Switch>
                    <PageRoute path={path + '/my-details'}>
                        <UserEditDetails />
                    </PageRoute>
                    <PageRoute path={path + '/users'}>
                        <UserPage />
                    </PageRoute>
                    <PageRoute path={path + '/marketplace'}>
                        <MarketplacePage />
                    </PageRoute>

                    {side === 'advertiser' && (
                        // todo extract in dedicated component and move loadings of data specific to advertiser into it
                        <Switch>
                            <Redirect from={path} exact to={path + '/dashboard'} />
                            <PageRoute path={path + '/support'}>
                                <AdvertiserSupportPage />
                            </PageRoute>
                            <PageRoute path={path + '/dashboard'}>
                                <DashboardPage />
                            </PageRoute>
                            <PageRoute path={path + '/messages/new'} permission='messages'>
                                <NewMessageAdvertiserPage />
                            </PageRoute>
                            <PageRoute path={path + '/messages'} permission='messages'>
                                <MessagePage />
                            </PageRoute>
                            <PageRoute path={path + '/list-program'}>
                                <ProgramListPage />
                            </PageRoute>
                            <PageRoute path={path + onBoardingRoutingSubPath}>
                                <AdvertiserSteps />
                            </PageRoute>
                            {/* <PageRoute path={path + '/config'} permission='edit'><AdvertiserEditDetails app={app} /></PageRoute> */}
                            {/* commented instead of deleted because we may re-integrate it later after rework */}
                            <PageRoute path={path + '/api'} permission='edit'>
                                <ApiKeyPage />
                            </PageRoute>
                            <PageRoute
                                path={path + '/billing/:tabId(subscription|invoices|details)?'}
                                permission='billing'
                                subscriptionOptional
                            >
                                <AdvertiserBilling />
                            </PageRoute>
                            <Redirect
                                exact
                                from={path + '/products'}
                                to={path + '/products/requests'}
                            />
                            <PageRoute
                                path={path + '/products/:tabId(requests|list)'}
                                permission='products'
                            >
                                {advertiser?.plan === 'track' ? (
                                    <ProductPageRestriction />
                                ) : (
                                    <AdvertiserProducts />
                                )}
                            </PageRoute>
                            <PageRoute path={path + '/create-program'} permission='admin'>
                                <AdvertiserProgramsCreate />
                            </PageRoute>
                            <Route path={pathProgram}>
                                <ProgramProvider>
                                    <Switch>
                                        <PageRoute
                                            path={
                                                pathProgram +
                                                '/:tabId(settings|commissions|tracking)'
                                            }
                                            permission='settings'
                                        >
                                            <AdvertiserProgramsConfigurationPage />
                                        </PageRoute>
                                        <PageRoute path={pathProgram + '/ads'} permission='ads'>
                                            <AdvertiserAds />
                                        </PageRoute>
                                        <PageRoute path={pathProgram + '/clicks'}>
                                            <ClicksPage />
                                        </PageRoute>
                                        <PageRoute path={pathProgram + '/vouchers'}>
                                            <AdvertiserVouchersList />
                                        </PageRoute>
                                        <PageRoute path={pathProgram + '/link-builder'}>
                                            <AdvertiserLinkBuilder app={app} />
                                        </PageRoute>
                                        <PageRoute path={pathProgram + '/reporting'}>
                                            <AdvertiserReporting app={app} />
                                        </PageRoute>
                                        <PageRoute path={pathProgram + '/feeds'}>
                                            <AdvertiserFeedsPage />
                                        </PageRoute>

                                        <PageRoute
                                            path={
                                                pathProgram + '/payments/:paymentRequestId/details'
                                            }
                                            permission='payments'
                                        >
                                            <PaymentRequestDetails app={app} />
                                        </PageRoute>
                                        <PageRoute
                                            path={pathProgram + '/:tabId(payments|earnings)'}
                                            permission='payments'
                                        >
                                            <AdvertiserPayments />
                                        </PageRoute>

                                        <PageRoute
                                            path={pathProgram + '/conversions/export'}
                                            permission='conversions'
                                        >
                                            <ConversionsExport />
                                        </PageRoute>
                                        <PageRoute
                                            path={pathProgram + '/conversions'}
                                            permission='conversions'
                                        >
                                            <AdvertiserConversionsList />
                                        </PageRoute>

                                        <PageRoute
                                            path={pathProgram + '/affiliates/:partnershipId/edit'}
                                            permission='partnerships'
                                        >
                                            <AdvertiserPartnershipsEdit app={app} />
                                        </PageRoute>
                                        <PageRoute
                                            path={pathProgram + '/affiliates'}
                                            permission='partnerships'
                                        >
                                            <AdvertiserPartnerships app={app} />
                                        </PageRoute>
                                        <Route path={pathProgram + '/*'}>
                                            <Page404 variant='auth' />
                                        </Route>
                                    </Switch>
                                </ProgramProvider>
                            </Route>
                            <Route path={path + '/*'}>
                                <Page404 variant='auth' />
                            </Route>
                        </Switch>
                    )}

                    {side === 'publisher' && (
                        // todo extract in dedicated component and move loadings of data specific to publisher into it
                        <Switch>
                            <Redirect from={path} exact to={path + '/dashboard'} />
                            <PageRoute path={path + '/support'}>
                                <PublisherSupportPage />
                            </PageRoute>
                            <PageRoute path={path + '/dashboard'}>
                                <DashboardPage />
                            </PageRoute>
                            <PageRoute path={path + '/list-affiliate-profile'}>
                                <AffiliateProfileListPage />
                            </PageRoute>
                            <PageRoute path={path + '/messages/new'} permission='messages'>
                                <NewMessagePublisherPage />
                            </PageRoute>
                            <PageRoute path={path + '/messages'} permission='messages'>
                                <MessagePage />
                            </PageRoute>
                            <PageRoute path={path + onBoardingRoutingSubPath}>
                                <PublisherSteps />
                            </PageRoute>
                            <PageRoute path={path + '/config'} permission='edit'>
                                <PublisherDetailsPage />
                            </PageRoute>
                            <PageRoute path={path + '/api'} permission='edit'>
                                <ApiKeyPage />
                            </PageRoute>
                            <PageRoute path={path + '/products'} permission='products'>
                                <PublisherProducts app={app} />
                            </PageRoute>
                            <PageRoute path={path + '/add-profile'} permission='admin'>
                                <PublisherProfileAdd />
                            </PageRoute>
                            <PageRoute path={path + '/integration'}>
                                <PublisherIntegrationPage />
                            </PageRoute>
                            <Route path={pathAffiliate}>
                                <AffiliateProvider>
                                    <Switch>
                                        <PageRoute
                                            path={pathAffiliate + '/settings/edit'}
                                            permission='settings'
                                        >
                                            <PublisherProfileEdit />
                                        </PageRoute>
                                        <PageRoute
                                            exact
                                            path={pathAffiliate + '/settings'}
                                            permission='settings'
                                        >
                                            <AffiliateProfileConfigurationPage />
                                        </PageRoute>
                                        <PageRoute path={pathAffiliate + '/ads'} permission='ads'>
                                            <PublisherAds app={app} />
                                        </PageRoute>
                                        <PageRoute path={pathAffiliate + '/clicks'}>
                                            <ClicksPage />
                                        </PageRoute>
                                        <PageRoute path={pathAffiliate + '/vouchers'}>
                                            <PublisherVouchersList />
                                        </PageRoute>
                                        <PageRoute path={pathAffiliate + '/link-builder'}>
                                            <PublisherLinkBuilder app={app} />
                                        </PageRoute>
                                        <PageRoute path={pathAffiliate + '/reporting'}>
                                            <PublisherReporting app={app} />
                                        </PageRoute>
                                        <PageRoute path={pathAffiliate + '/feeds'}>
                                            <PublisherFeedsPage />
                                        </PageRoute>

                                        <PageRoute
                                            path={
                                                pathAffiliate +
                                                '/payments/:paymentRequestId/details'
                                            }
                                            permission='payments'
                                        >
                                            <PaymentRequestDetails app={app} />
                                        </PageRoute>
                                        <PageRoute
                                            path={pathAffiliate + '/:tabId(payments|earnings)'}
                                            permission='payments'
                                        >
                                            <PublisherPayments app={app} />
                                        </PageRoute>

                                        <PageRoute
                                            path={pathAffiliate + '/conversions/export'}
                                            permission='conversions'
                                        >
                                            <ConversionsExport />
                                        </PageRoute>
                                        <PageRoute
                                            path={pathAffiliate + '/conversions'}
                                            permission='conversions'
                                        >
                                            <PublisherConversionsList />
                                        </PageRoute>
                                        <PageRoute
                                            path={pathAffiliate + '/partnerships'}
                                            permission='partnerships'
                                        >
                                            <PublisherPartnerships app={app} />
                                        </PageRoute>
                                        <Route path={path + '/*'}>
                                            <Page404 variant='auth' />
                                        </Route>
                                    </Switch>
                                </AffiliateProvider>
                            </Route>
                            <Route path={path + '/*'}>
                                <Page404 variant='auth' />
                            </Route>
                        </Switch>
                    )}

                    {side === 'admin' && (
                        // todo extract in dedicated component and move loadings of data specific to admin into it
                        <Switch>
                            <Redirect from={path} exact to={path + '/advertisers'} />
                            <PageRoute path={path + '/advertisers'}>
                                <AdminAdvertiserPublisher />
                            </PageRoute>
                            <PageRoute path={path + '/publishers'}>
                                <AdminAdvertiserPublisher />
                            </PageRoute>
                            <PageRoute path={path + '/am-sites'}>
                                <AdminProfilesAm />
                            </PageRoute>
                            <PageRoute path={path + '/am-programmes'}>
                                <AdminProgramsAm />
                            </PageRoute>
                            <PageRoute path={path + '/am-products'}>
                                <AdminProductsAM />
                            </PageRoute>
                            <PageRoute path={path + '/invoices'}>
                                <AdminInvoices />
                            </PageRoute>
                            <PageRoute path={path + '/payment-requests'}>
                                <AdminPaymentRequests />
                            </PageRoute>
                            <PageRoute path={path + '/partnerships'}>
                                <AdminPartnerships />
                            </PageRoute>
                            <PageRoute path={path + '/events'}>
                                <AdminEvents />
                            </PageRoute>
                            <PageRoute path={path + '/*'}>
                                <Page404 variant='auth' />
                            </PageRoute>
                        </Switch>
                    )}
                </Switch>
            }
        />
    );
}
