import { create } from 'zustand';

import { PeriodComp, PeriodRef } from '@components/generic/DateRangePicker';

import { MongoId } from '@libs/types';

export type DashboardState = {
    selectedSubEntities: MongoId[];
    setSelectedSubEntities: (selectedSubEntities: MongoId[]) => void;

    currencySelected: string | undefined;
    setSelectedCurrency: (currencySelected: string | undefined) => void;

    showTopProducts: boolean;
    setShowTopProducts(showTopProducts: boolean): void;

    selectedReferencePeriod: PeriodRef;
    setSelectedReferencePeriod: (period: PeriodRef) => void;

    selectedComparisonPeriod: PeriodComp;
    setSelectedComparisonPeriod: (period: PeriodComp) => void;

    areFiltersComplete: () => boolean;
};

export const useDashboardStore = create<DashboardState>()((set, get) => ({
    selectedSubEntities: [],
    setSelectedSubEntities: (selectedSubEntities) => set({ selectedSubEntities }),

    currencySelected: undefined,
    setSelectedCurrency: (currencySelected) => set({ currencySelected }),

    showTopProducts: true,
    setShowTopProducts: (showTopProducts) => set({ showTopProducts }),

    selectedReferencePeriod: 'last_4_weeks',
    setSelectedReferencePeriod: (period) => set({ selectedReferencePeriod: period }),

    selectedComparisonPeriod: 'autoPrevious',
    setSelectedComparisonPeriod: (period) => set({ selectedComparisonPeriod: period }),

    areFiltersComplete: () => {
        return (
            get().currencySelected !== undefined &&
            get().selectedReferencePeriod !== null &&
            get().selectedComparisonPeriod !== null &&
            get().selectedSubEntities.length > 0
        );
    },
}));
