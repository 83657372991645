import { useEffect, useRef, useState } from 'react';

export type TimerStatus = 'idle' | 'running' | 'paused' | 'finished';
export type TimerType = 'up' | 'down';

export interface Timer {
    status: TimerStatus;
    /** in milliseconds */
    time: number;
    timerType: TimerType;
    /** percent round at unit */
    progress: number;

    start(time?: number): void;
    pause(): void;
    reset(): void;
}

export type TimerOptions = {
    /** in milliseconds */
    initialDuration?: number | null;
    timerType?: TimerType;
    onEnd?: () => void;
    interval?: number;
    autoStart?: boolean;
};
export function useTimer({
    initialDuration,
    timerType = 'down',
    onEnd,
    interval = 500,
    autoStart = true,
}: TimerOptions = {}): Timer {
    const [duration, setDuration] = useState<number | null>(initialDuration ?? null);
    const [time, setTime] = useState<number>(getStartTime(duration, timerType));
    const [status, setStatus] = useState<TimerStatus>('idle');
    const intervalIdRef = useRef<number | null>(null);

    function start(durationInput?: number) {
        const durationFinal = durationInput ?? duration;
        if (!durationFinal) throw new Error('duration is required');
        if (durationInput) {
            setDuration(durationInput);
            setTime(getStartTime(durationInput, timerType));
        }
        setStatus('running');
        if (intervalIdRef.current === null) {
            intervalIdRef.current = window.setInterval(tick, interval);
        }
    }

    function getStartTime(duration: number | null | undefined, type: TimerType) {
        return type === 'down' ? duration ?? 0 : 0;
    }

    function tick() {
        setTime((time) =>
            limit(timerType === 'down' ? time - interval : time + interval, {
                min: 0,
                max: duration,
            }),
        );
    }

    useEffect(() => {
        const isEnd = timerType === 'down' ? time === 0 : time === duration;
        if (isEnd) {
            setStatus('finished');
            onEnd?.();
        }
    }, [time, duration, timerType, onEnd]);

    function stopTick() {
        if (intervalIdRef.current) clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
    }

    function pause() {
        if (status !== 'running') return;
        if (!intervalIdRef.current) return;

        stopTick();
        setStatus('paused');
    }

    function reset() {
        stopTick();
        setStatus('idle');
        setTime(getStartTime(duration, timerType));
    }

    useEffect(() => {
        if (autoStart && duration) start();
        return () => stopTick();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    // we want hook to render on first mount of component only

    function getProgress(): number {
        if (duration == null) return 0;
        const ratio = Math.round((time / duration) * 100);
        return timerType === 'down' ? 100 - ratio : ratio;
    }

    return {
        status,
        time,
        timerType,
        progress: getProgress(),
        start,
        pause,
        reset,
    };
}

function limit(value: number, { min, max }: { min?: number | null; max?: number | null }): number {
    if (min != null && value < min) return min;
    if (max != null && value > max) return max;
    return value;
}
