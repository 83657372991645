import _ from 'lodash';

import { downloadBlob } from '@libs/fileHelper';
import { getJwt } from '@libs/jwt';

import { getAPISubUrlForPublisher } from '@api/common/utils';

export const PUBLISHER_ADS_LOADING = 'PUBLISHER_ADS_LOADING';
export const FETCH_PUBLISHER_ADS = 'FETCH_PUBLISHER_ADS';
export const FETCH_PUBLISHER_ADS_SUCCESS = 'FETCH_PUBLISHER_ADS_SUCCESS';
export const FETCH_PUBLISHER_ADS_FAILURE = 'FETCH_PUBLISHER_ADS_FAILURE';

export const FETCH_PUBLISHER_AD_INFO = 'FETCH_PUBLISHER_AD_INFO';

export const DOWNLOAD_PUBLISHER_AD = 'DOWNLOAD_PUBLISHER_AD';
export const DOWNLOAD_PUBLISHER_AD_SUCCESS = 'DOWNLOAD_PUBLISHER_AD_SUCCESS';
export const DOWNLOAD_PUBLISHER_AD_FAILURE = 'DOWNLOAD_PUBLISHER_AD_FAILURE';

export const OPEN_PUBLISHER_AD_PREVIEW = 'OPEN_PUBLISHER_AD_PREVIEW';

export const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS';

export function publisherAdsLoading() {
    return {
        type: PUBLISHER_ADS_LOADING,
    };
}

export function fetchPublisherAds(queryCriteria) {
    let qs = '?affiliateProfile=' + queryCriteria.affiliateProfile;

    if (!_.isEmpty(queryCriteria.type)) {
        qs = qs.concat('&type=' + queryCriteria.type);
    }
    if (!_.isEmpty(queryCriteria.status)) {
        qs = qs.concat('&status=' + queryCriteria.status);
    }
    if (!_.isEmpty(queryCriteria.program)) {
        qs = qs.concat('&program=' + queryCriteria.program);
    }
    if (!_.isEmpty(queryCriteria.search)) {
        qs = qs.concat('&search=' + queryCriteria.search);
    }

    const promise = fetch(getAPISubUrlForPublisher() + '/ads.list' + qs, {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
        },
    });
    return {
        type: FETCH_PUBLISHER_ADS,
        payload: promise,
    };
}

export function fetchPublisherAdsSuccess(data) {
    return {
        type: FETCH_PUBLISHER_ADS_SUCCESS,
        payload: data,
    };
}

export function fetchPublisherAdsFailure(error) {
    return {
        type: FETCH_PUBLISHER_ADS_FAILURE,
        payload: error,
    };
}

export function downloadPublisherAd(affiliateProfileId, adId, programId) {
    let qs = '?id=' + adId;
    qs = qs.concat('&program=' + programId);
    qs = qs.concat('&affiliateProfile=' + affiliateProfileId);

    const promise = fetch(getAPISubUrlForPublisher() + '/ads.get' + qs, {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
        },
    });

    return {
        type: DOWNLOAD_PUBLISHER_AD,
        payload: promise,
    };
}

export function downloadPublisherAdSuccess(ad, data) {
    downloadBlob(data, ad.title + '.zip');
    return { type: DOWNLOAD_PUBLISHER_AD_SUCCESS };
}

export function downloadPublisherAdFailure(error) {
    return {
        type: DOWNLOAD_PUBLISHER_AD_FAILURE,
        payload: error,
    };
}

export function openPublisherAdPreview(ad) {
    return {
        type: OPEN_PUBLISHER_AD_PREVIEW,
        payload: ad,
    };
}

export function closeAllDialogs() {
    return {
        type: CLOSE_ALL_DIALOGS,
    };
}
