import {
    CLOSE_ALL_DIALOGS,
    DOWNLOAD_PUBLISHER_AD_FAILURE,
    FETCH_PUBLISHER_ADS_FAILURE,
    FETCH_PUBLISHER_ADS_SUCCESS,
    OPEN_PUBLISHER_AD_PREVIEW,
    PUBLISHER_ADS_LOADING,
} from '@actions/publisherAds';

const INITIAL_STATE = {
    loading: false,
    data: null,
    error: null,
    queryCriteria: {
        affiliateProfile: '',
        type: '',
        status: '',
        program: '',
        search: '',
    },
    previewAdDialog: {
        isOpen: false,
        ad: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case PUBLISHER_ADS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PUBLISHER_ADS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case FETCH_PUBLISHER_ADS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case OPEN_PUBLISHER_AD_PREVIEW: {
            return {
                ...state,
                previewAdDialog: {
                    isOpen: true,
                    ad: action.payload,
                    info: null,
                },
            };
        }

        case DOWNLOAD_PUBLISHER_AD_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case CLOSE_ALL_DIALOGS: {
            return {
                ...state,
                previewAdDialog: {
                    isOpen: false,
                    ad: null,
                    info: null,
                },
            };
        }
        default:
            break;
    }

    return state;
}
