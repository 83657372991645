import error401Image from '@assets/images/error401.svg';

import { ReactNode } from 'react';

import { unauthorizedPagesStyles } from './UnauthorizedBySubscription';

// img src: https://dribbble.com/shots/6287961-Error-401

interface UnauthorizedPageProps {
    children: ReactNode;
}
export default function UnauthorizedPage({ children }: UnauthorizedPageProps) {
    return (
        <div style={unauthorizedPagesStyles.page}>
            <img src={error401Image} alt='unauthorized' style={{ maxHeight: '50%' }} />
            {children}
        </div>
    );
}
