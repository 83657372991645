import _ from 'lodash';

import { getJwt } from '@libs/jwt';

import { getAPISubUrlForAdvertiser } from '@api/common/utils';

export const ADVERTISER_PRODUCTS_LOADING = 'ADVERTISER_PRODUCTS_LOADING';

export const FETCH_ADVERTISER_PRODUCTS = 'FETCH_ADVERTISER_PRODUCTS';
export const FETCH_ADVERTISER_PRODUCTS_SUCCESS = 'FETCH_ADVERTISER_PRODUCTS_SUCCESS';
export const FETCH_ADVERTISER_PRODUCTS_FAILURE = 'FETCH_ADVERTISER_PRODUCTS_FAILURE';

export const FETCH_ADVERTISER_PRODUCT_REQUESTS = 'FETCH_ADVERTISER_PRODUCT_REQUESTS';
export const FETCH_ADVERTISER_PRODUCT_REQUESTS_SUCCESS =
    'FETCH_ADVERTISER_PRODUCT_REQUESTS_SUCCESS';
export const FETCH_ADVERTISER_PRODUCT_REQUESTS_FAILURE =
    'FETCH_ADVERTISER_PRODUCT_REQUESTS_FAILURE';

export const UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS = 'UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS';
export const UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_SUCCESS =
    'UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_SUCCESS';
export const UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_FAILURE =
    'UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_FAILURE';

export const DELETE_ADVERTISER_PRODUCT = 'DELETE_ADVERTISER_PRODUCT';
export const DELETE_ADVERTISER_PRODUCT_SUCCESS = 'DELETE_ADVERTISER_PRODUCT_SUCCESS';
export const DELETE_ADVERTISER_PRODUCT_FAILURE = 'DELETE_ADVERTISER_PRODUCT_FAILURE';

export const UPDATE_ADVERTISER_PRODUCT_STATUS = 'UPDATE_ADVERTISER_PRODUCT_STATUS';
export const UPDATE_ADVERTISER_PRODUCT_STATUS_SUCCESS = 'UPDATE_ADVERTISER_PRODUCT_STATUS_SUCCESS';
export const UPDATE_ADVERTISER_PRODUCT_STATUS_FAILURE = 'UPDATE_ADVERTISER_PRODUCT_STATUS_FAILURE';

export const CHECK_ADVERTISER_PRODUCTS_QUOTA = 'CHECK_ADVERTISER_PRODUCTS_QUOTA';
export const CHECK_ADVERTISER_PRODUCTS_QUOTA_SUCCESS = 'CHECK_ADVERTISER_PRODUCTS_QUOTA_SUCCESS';
export const CHECK_ADVERTISER_PRODUCTS_QUOTA_FAILURE = 'CHECK_ADVERTISER_PRODUCTS_QUOTA_FAILURE';

export const ADD_ADVERTISER_PRODUCT_IMAGE = 'ADD_ADVERTISER_PRODUCT_IMAGE';
export const ADD_ADVERTISER_PRODUCT_IMAGE_SUCCESS = 'ADD_ADVERTISER_PRODUCT_IMAGE_SUCCESS';
export const ADD_ADVERTISER_PRODUCT_IMAGE_FAILURE = 'ADD_ADVERTISER_PRODUCT_IMAGE_FAILURE';

export const SOFT_DELETE_ADVERTISER_PRODUCT_IMAGES = 'SOFT_DELETE_ADVERTISER_PRODUCT_IMAGES';

export const DELETE_ADVERTISER_PRODUCT_IMAGES = 'DELETE_ADVERTISER_PRODUCT_IMAGES';
export const DELETE_ADVERTISER_PRODUCT_IMAGES_SUCCESS = 'DELETE_ADVERTISER_PRODUCT_IMAGES_SUCCESS';
export const DELETE_ADVERTISER_PRODUCT_IMAGES_FAILURE = 'DELETE_ADVERTISER_PRODUCT_IMAGES_FAILURE';

export const CREATE_ADVERTISER_PRODUCT = 'CREATE_ADVERTISER_PRODUCT';
export const CREATE_ADVERTISER_PRODUCT_SUCCESS = 'CREATE_ADVERTISER_PRODUCT_SUCCESS';
export const CREATE_ADVERTISER_PRODUCT_FAILURE = 'CREATE_ADVERTISER_PRODUCT_FAILURE';

export const UPDATE_ADVERTISER_PRODUCT = 'UPDATE_ADVERTISER_PRODUCT';
export const UPDATE_ADVERTISER_PRODUCT_SUCCESS = 'UPDATE_ADVERTISER_PRODUCT_SUCCESS';
export const UPDATE_ADVERTISER_PRODUCT_FAILURE = 'UPDATE_ADVERTISER_PRODUCT_FAILURE';

export const OPEN_ADD_PRODUCT_DIALOG = 'OPEN_ADD_PRODUCT_DIALOG';
export const OPEN_EDIT_PRODUCT_DIALOG = 'OPEN_EDIT_PRODUCT_DIALOG';
export const OPEN_DELETE_PRODUCT_DIALOG = 'OPEN_DELETE_PRODUCT_DIALOG';
export const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS';
export const ADVERTISER_PRODUCTS_PRODUCTS_FILTER_CHANGE =
    'ADVERTISER_PRODUCTS_PRODUCTS_FILTER_CHANGE';

export function advertiserProductsLoading() {
    return {
        type: ADVERTISER_PRODUCTS_LOADING,
    };
}
export function advertiserProductsStatusFilterChange(data) {
    return {
        type: ADVERTISER_PRODUCTS_PRODUCTS_FILTER_CHANGE,
        payload: data,
    };
}

export function fetchAdvertiserProducts(queryCriteria) {
    const qs = buildQueryString(queryCriteria);
    const promise = fetch(getAPISubUrlForAdvertiser() + '/products.list' + qs, {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
        },
    });
    return {
        type: FETCH_ADVERTISER_PRODUCTS,
        payload: promise,
    };

    function buildQueryString(queryCriteria) {
        const queries = [];
        if (!_.isEmpty(queryCriteria.programs)) {
            queryCriteria.programs.map((programId) => {
                queries.push('programs=' + programId);
            });
        }
        if (!_.isEmpty(queryCriteria.status)) {
            queries.push('status=' + queryCriteria.status);
        }
        if (!_.isEmpty(queryCriteria.search)) {
            queries.push('search=' + queryCriteria.search);
        }
        if (queries.length > 0) {
            return '?' + queries.join('&');
        } else {
            return '';
        }
    }
}

export function fetchAdvertiserProductsSuccess(data) {
    return {
        type: FETCH_ADVERTISER_PRODUCTS_SUCCESS,
        payload: data,
    };
}

export function fetchAdvertiserProductsFailure(error) {
    return {
        type: FETCH_ADVERTISER_PRODUCTS_FAILURE,
        payload: error,
    };
}

export function fetchAdvertiserProductRequests(queryCriteria) {
    const qs = buildQueryString(queryCriteria);
    const promise = fetch(getAPISubUrlForAdvertiser() + '/products/request.list' + qs, {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
        },
    });
    return {
        type: FETCH_ADVERTISER_PRODUCT_REQUESTS,
        payload: promise,
    };

    function buildQueryString(queryCriteria) {
        const queries = [];
        if (!_.isEmpty(queryCriteria.programs)) {
            queryCriteria.programs.map((programId) => {
                queries.push('programs=' + programId);
            });
        }
        if (!_.isEmpty(queryCriteria.status)) {
            queryCriteria.status.map((status) => {
                queries.push('status=' + status);
            });
        }
        if (queryCriteria.affiliateProfile !== null) {
            queries.push('affiliateProfile=' + queryCriteria.affiliateProfile);
        }
        if (queryCriteria.product !== null) {
            queries.push('product=' + queryCriteria.product);
        }
        if (!_.isEmpty(queryCriteria.search)) {
            queries.push('search=' + queryCriteria.search);
        }
        if (queries.length > 0) {
            return '?' + queries.join('&');
        } else {
            return '';
        }
    }
}

export function fetchAdvertiserProductRequestsSuccess(data) {
    return {
        type: FETCH_ADVERTISER_PRODUCT_REQUESTS_SUCCESS,
        payload: data,
    };
}

export function fetchAdvertiserProductRequestsFailure(error) {
    return {
        type: FETCH_ADVERTISER_PRODUCT_REQUESTS_FAILURE,
        payload: error,
    };
}

export function updateAdvertiserProductRequestStatus(productRequestId, status, messageOption) {
    const bodyObj = {
        productRequest: productRequestId,
        reason: messageOption,
    };

    let action;
    switch (status) {
        case 'request_accepted':
            action = 'request.accept';
            break;
        case 'request_refused':
            action = 'request.refuse';
            break;
    }

    const promise = fetch(getAPISubUrlForAdvertiser() + '/products/' + action, {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS,
        payload: promise,
    };
}

export function updateAdvertiserProductRequestStatusSuccess(updatedProductRequest, allData) {
    const updatedDatas = _.map(allData, function (productRequest) {
        if (productRequest.id == updatedProductRequest.id) {
            productRequest.status = updatedProductRequest.status;
        }
        if (
            productRequest.affiliateProfile.id === updatedProductRequest.affiliateProfile.id &&
            productRequest.program.id === updatedProductRequest.program.id
        ) {
            productRequest.partnership = updatedProductRequest.partnership;
        }
        return productRequest;
    });

    return {
        type: UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_SUCCESS,
        payload: updatedDatas,
    };
}

export function updateAdvertiserProductRequestStatusFailure(error) {
    return {
        type: UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_FAILURE,
        payload: error,
    };
}

export function deleteAdvertiserProduct(productId) {
    const bodyObj = {
        id: productId,
    };

    const promise = fetch(getAPISubUrlForAdvertiser() + '/products.delete', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: DELETE_ADVERTISER_PRODUCT,
        payload: promise,
    };
}

export function deleteAdvertiserProductSuccess(productId, allData) {
    const updatedDatas = _.filter(allData, function (product) {
        return product.id !== productId;
    });

    return {
        type: DELETE_ADVERTISER_PRODUCT_SUCCESS,
        payload: updatedDatas,
    };
}

export function deleteAdvertiserProductFailure(error) {
    return {
        type: DELETE_ADVERTISER_PRODUCT_FAILURE,
        payload: error,
    };
}

export function updateAdvertiserProductStatus(productId, status) {
    const bodyObj = {
        id: productId,
    };

    let action;
    switch (status) {
        case 'enabled':
            action = 'enable';
            break;
        case 'disabled':
            action = 'disable';
            break;
    }

    const promise = fetch(getAPISubUrlForAdvertiser() + '/products.' + action, {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: UPDATE_ADVERTISER_PRODUCT_STATUS,
        payload: promise,
    };
}

export function updateAdvertiserProductStatusSuccess(productId, status, allData) {
    const updatedDatas = _.map(allData, function (product) {
        if (product.id == productId) {
            product.status = status;
        }
        return product;
    });

    return {
        type: UPDATE_ADVERTISER_PRODUCT_STATUS_SUCCESS,
        payload: updatedDatas,
    };
}

export function updateAdvertiserProductStatusFailure(error) {
    return {
        type: UPDATE_ADVERTISER_PRODUCT_STATUS_FAILURE,
        payload: error,
    };
}

export function checkAdvertiserProductsQuota() {
    const promise = fetch(getAPISubUrlForAdvertiser() + '/products.quotaCheck', {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
        },
    });

    return {
        type: CHECK_ADVERTISER_PRODUCTS_QUOTA,
        payload: promise,
    };
}

export function checkAdvertiserProductsQuotaSuccess(result) {
    return {
        type: CHECK_ADVERTISER_PRODUCTS_QUOTA_SUCCESS,
        payload: result,
    };
}

export function checkAdvertiserProductsQuotaFailure(error) {
    return {
        type: CHECK_ADVERTISER_PRODUCTS_QUOTA_FAILURE,
        payload: error,
    };
}

export function addAdvertiserProductImage(file) {
    const form = new FormData();
    form.append('file', file);

    const promise = fetch(getAPISubUrlForAdvertiser() + '/products/media.upload', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
        },
        body: form,
    });

    return {
        type: ADD_ADVERTISER_PRODUCT_IMAGE,
        payload: promise,
    };
}

export function addAdvertiserProductImageSuccess(result, fields, toDeleteOnCancelImageIds) {
    const updatedImages = _.cloneDeep(fields.images);
    updatedImages.push(result);

    const updatedToDeleteOnCancelImagesId = _.cloneDeep(toDeleteOnCancelImageIds);
    updatedToDeleteOnCancelImagesId.push(result.id);

    return {
        type: ADD_ADVERTISER_PRODUCT_IMAGE_SUCCESS,
        payload: updatedImages,
        toDeleteOnCancelImageIds: updatedToDeleteOnCancelImagesId,
    };
}

export function addAdvertiserProductImageFailure(error) {
    return {
        type: ADD_ADVERTISER_PRODUCT_IMAGE_FAILURE,
        payload: error,
    };
}

export function softDeleteAdvertiserProductImage(imageId, fields, toDeleteOnSubmitImageIds) {
    let updatedImages = _.cloneDeep(fields.images);
    updatedImages = _.filter(updatedImages, function (image) {
        return image.id !== imageId;
    });

    const updatedToDeleteOnSubmitImagesId = _.cloneDeep(toDeleteOnSubmitImageIds);
    updatedToDeleteOnSubmitImagesId.push(imageId);

    return {
        type: SOFT_DELETE_ADVERTISER_PRODUCT_IMAGES,
        payload: updatedImages,
        toDeleteOnSubmitImageIds: updatedToDeleteOnSubmitImagesId,
    };
}

export function deleteAdvertiserProductImages(imageIds) {
    const bodyObj = {
        images: imageIds,
    };

    const promise = fetch(getAPISubUrlForAdvertiser() + '/products/media.delete', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: DELETE_ADVERTISER_PRODUCT_IMAGES,
        payload: promise,
    };
}

export function deleteAdvertiserProductImagesSuccess(imageId, fields) {
    // let updatedImages = _.cloneDeep(fields.images);
    // updatedImages = _.filter(updatedImages, function (image) {
    //     return image.id !== imageId;
    // });

    return {
        type: DELETE_ADVERTISER_PRODUCT_IMAGES_SUCCESS,
        // payload: updatedImages
    };
}

export function deleteAdvertiserProductImagesFailure(error) {
    return {
        type: DELETE_ADVERTISER_PRODUCT_IMAGES_FAILURE,
        payload: error,
    };
}

export function createAdvertiserProduct(fields) {
    const bodyObj = {
        program: fields.program,
        title: fields.title,
        description: fields.description,
        lang: fields.lang,
        category: fields.category,
        url: fields.url,
        price: Math.round(parseFloat(fields.price) * 100),
        deliveryTime: fields.deliveryTime,
        images: _.map(fields.images, function (image) {
            return image.id;
        }),
    };
    if (!_.isEmpty(fields.comment)) {
        bodyObj.comment = fields.comment;
    }

    const promise = fetch(getAPISubUrlForAdvertiser() + '/products.create', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: CREATE_ADVERTISER_PRODUCT,
        payload: promise,
    };
}

export function createAdvertiserProductSuccess(product, allData) {
    const updatedDatas = allData;
    updatedDatas.push(product);

    return {
        type: CREATE_ADVERTISER_PRODUCT_SUCCESS,
        payload: updatedDatas,
    };
}

export function createAdvertiserProductFailure(error) {
    return {
        type: CREATE_ADVERTISER_PRODUCT_FAILURE,
        payload: error,
    };
}

export function updateAdvertiserProduct(fields) {
    let bodyObj;
    if (fields.statusAdmin === 'accepted') {
        bodyObj = {
            id: fields.id,
            lang: fields.lang,
            category: fields.category,
            url: fields.url,
            price: Math.round(parseFloat(fields.price) * 100),
            deliveryTime: fields.deliveryTime,
        };
    } else {
        bodyObj = {
            id: fields.id,
            title: fields.title,
            description: fields.description,
            lang: fields.lang,
            category: fields.category,
            url: fields.url,
            price: Math.round(parseFloat(fields.price) * 100),
            deliveryTime: fields.deliveryTime,
            images: _.map(fields.images, function (image) {
                return image.id;
            }),
        };
        if (!_.isEmpty(fields.comment)) {
            bodyObj.comment = fields.comment;
        }
    }

    const promise = fetch(getAPISubUrlForAdvertiser() + '/products.update', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: UPDATE_ADVERTISER_PRODUCT,
        payload: promise,
    };
}

export function updateAdvertiserProductSuccess(product, allData) {
    const updatedDatas = _.map(allData, function (data) {
        if (data.id === product.id) {
            return product;
        } else {
            return data;
        }
    });

    return {
        type: UPDATE_ADVERTISER_PRODUCT_SUCCESS,
        payload: updatedDatas,
    };
}

export function updateAdvertiserProductFailure(error) {
    return {
        type: UPDATE_ADVERTISER_PRODUCT_FAILURE,
        payload: error,
    };
}

export function openAddProductDialog() {
    return {
        type: OPEN_ADD_PRODUCT_DIALOG,
    };
}

export function openEditProductDialog(product) {
    return {
        type: OPEN_EDIT_PRODUCT_DIALOG,
        product: product,
    };
}

export function openDeleteProductDialog(product) {
    return {
        type: OPEN_DELETE_PRODUCT_DIALOG,
        product: product,
    };
}

export function closeAllDialogs() {
    return {
        type: CLOSE_ALL_DIALOGS,
    };
}
