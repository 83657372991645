import backgroundImg from '@assets/images/bgBlurred.jpg';

export function BackgroundNotAuth({ style, ...props }: JSX.IntrinsicElements['div']) {
    return (
        <div
            style={{
                height: '100%',
                backgroundSize: 'cover',
                backgroundImage: `url(${backgroundImg})`,
                backgroundAttachment: 'fixed',
                ...style,
            }}
            {...props}
        />
    );
}
