import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/types';

import { getUserType } from '@libs/getSharedVar';

export const blacklistedWords: Record<string, string> = {
    advertiser: '02452e3484c42211b1c2884ad9b60c93',
};

/**
 * ⚠️ finish with a '/' (for historic reasons...)
 */
export function getProductorBaseUrl() {
    return CONFIG.productor_url + '/';
}
export function getAPIBaseUrl() {
    return CONFIG.api_url + '/';
}

export function getAPISubUrlForAdmin() {
    return getAPIBaseUrl() + 'admin';
}
export function getAPISubUrlForAdvertiser() {
    return getAPIBaseUrl() + blacklistedWords.advertiser; // todo handle case where global var blacklistedWords is undefined
}

export function getAPISubUrlForPublisher() {
    return getAPIBaseUrl() + 'publisher';
}

export function getAPISubUrlForCurrentUser() {
    const userType = getUserType();
    switch (userType) {
        case 'advertiser':
            return getAPISubUrlForAdvertiser();
        case 'publisher':
            return getAPISubUrlForPublisher();
    }
}

/**
 * Throw an error if unexpected http response code
 */
export async function checkStatusForProductor(
    response: Response,
    expectedStatus: number | number[] = 200,
    lang: string,
) {
    const isAnExpectedStatus = Array.isArray(expectedStatus)
        ? expectedStatus.includes(response.status)
        : response.status === expectedStatus;
    if (isAnExpectedStatus) {
        return;
    }

    const errMsg = await getErrMsgForProductor(response, lang);
    const error = new ApiError(response.status, errMsg);
    throw error;
}
async function getErrMsgForProductor(
    response: Response,
    lang: string,
): Promise<string | undefined> {
    try {
        const body = await response.json();
        let msg = '';
        switch (lang.toUpperCase()) {
            case 'EN':
                msg = body?.content.en;
                break;
            case 'FR':
                msg = body?.content.fr;
                break;
            case 'ES':
                msg = body?.content.es;
                break;
            default:
                msg = body?.content.en;
                break;
        }
        return msg;
    } catch (error) {
        return undefined;
    }
}

export async function checkStatus(response: Response, expectedStatus: number | number[] = 200) {
    const isAnExpectedStatus = Array.isArray(expectedStatus)
        ? expectedStatus.includes(response.status)
        : response.status === expectedStatus;
    if (isAnExpectedStatus) {
        return;
    }

    checkForRateLimit(response);

    const errMsg = await getErrMsg(response);
    const error = new ApiError(response.status, errMsg);
    throw error;
}

async function getErrMsg(response: Response): Promise<string | undefined> {
    try {
        const body = await response.json();
        const msg = body?.message; // test
        return msg;
    } catch (error) {
        return undefined;
    }
}

export class ApiError extends Error {
    /** http status code */
    status: number;
    /** error msg returned by api */
    errorMsg?: string;
    /** error id */

    constructor(status: number, msg?: string) {
        super(`status ${status} - ${msg}`);
        this.name = 'ApiError';
        this.status = status;
        this.errorMsg = msg;

        if (this.stack) {
            this.stack = this.stack.split('\n').slice(2).join('\n');
        }
    }
}

type QueryTypeBasic = string | number | boolean | null | undefined;
type QueryType = QueryTypeBasic | QueryTypeBasic[];

/**
 * Build url query string from Object
 * supported query parameters type are string, number, boolean and array;
 * if no options return an empty string
 */
export function buildQueryString(queryParameters: { [key: string]: QueryType }) {
    const keyValues = pipe(Object.entries(queryParameters), [
        flattenArrayValue,
        filterUnsupportedValue,
        encode,
    ]);
    return keyValues.length !== 0 ? '?' + keyValues.join('&') : '';

    function pipe(input: any[], pipeline: Array<(value: any[]) => any[]>) {
        return pipeline.reduce((prev, func) => func(prev), input);
    }

    /**
     * Transform keyValue whose values are array in multiple keyValue
     */
    function flattenArrayValue(keyValues: [key: string, value: QueryType][]) {
        return keyValues.reduce(
            (prev, [key, value]) => {
                let keyValuesToAdd: [key: string, value: QueryTypeBasic][];
                if (!Array.isArray(value)) {
                    keyValuesToAdd = [[key, value]];
                } else {
                    keyValuesToAdd = value.map((subValue) => [key, subValue]);
                }
                return prev.concat(keyValuesToAdd);
            },
            [] as [key: string, value: QueryTypeBasic][],
        );
    }

    function filterUnsupportedValue(keyValues: [key: string, value: QueryTypeBasic][]) {
        return keyValues.filter(([, value]) =>
            ['string', 'number', 'boolean'].includes(typeof value),
        );
    }

    function encode(keyValues: [key: string, value: QueryTypeBasic][]) {
        return keyValues.map(
            ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        );
    }
}

/**
 * Add Sentry error reporting to wrapped api call.
 * Don't stop error propagation so caller can react accordingly
 */
export function sentryApiDecorator<Args extends unknown[], Return>(
    apiCall: (...args: Args) => Promise<Return>,
) {
    return async (...args: Args) => {
        try {
            return await apiCall(...args);
        } catch (error) {
            captureApiError(args, error as ApiError);
            throw error;
        }
    };
}

export function captureApiError(args: unknown[], error: ApiError) {
    Sentry.captureException(error, {
        contexts: {
            'api call': {
                args,
                status: error.status,
            },
        },
    });
}

export function logException(err: string, level: SeverityLevel = 'error') {
    switch (level) {
        case 'fatal':
        case 'error':
            console.error(err);
            break;

        case 'warning':
            console.warn(err);
            break;

        case 'info':
            console.info(err);
            break;

        case 'debug':
            console.debug(err);
            break;

        default:
            console.error(err);
            break;
    }
}

export async function getFileAsync(res: Response): Promise<File> {
    return new File([await res.blob()], getFilename(res));
}

export function getFilename(res: Response): string {
    const header = res.headers.get('Content-Disposition');
    if (header == null) {
        throw new Error('missing header "Content-Disposition"');
    }
    const match = header.match(/attachment; filename=(.+)/);
    const filename = match?.[1];
    if (filename == null) {
        throw new Error('missing filename');
    }
    return filename;
}

/**
 * Throw an error if response was rate limited
 */
export function checkForRateLimit(
    response: Response,
    { defaultRetryAfter = 20 }: { defaultRetryAfter?: number } = {},
) {
    if (response.status !== 429) {
        return;
    }

    const headers = Array.from(response.headers.entries()).reduce(
        (prev, [key, value]) => {
            prev[key] = value;
            return prev;
        },
        {} as { [key: string]: string },
    );

    const isRateLimitAdvanced = Object.keys(headers).some((key) =>
        key.startsWith(rateLimitHeaderPrefix),
    );
    if (!isRateLimitAdvanced) {
        const error: RateLimitError = {
            retryAfter: defaultRetryAfter,
        };
        throw error;
    } else {
        const isRouteRateLimit = Object.keys(headers).some((key) =>
            key.startsWith(rateLimitHeaderPrefix + '-route'),
        );
        const prefix =
            (isRouteRateLimit ? rateLimitHeaderPrefix + '-route' : rateLimitHeaderPrefix) +
            '-second';

        const error: RateLimitErrorAdvanced = {
            consumed: parseInt(headers[prefix + '-consumed']),
            remaining: parseInt(headers[prefix + '-remaining']),
            limit: parseInt(headers[prefix + '-limit']),
            reset: parseInt(headers[prefix + '-reset']),
            retryAfter: parseInt(headers[prefix + '-retry-after']),
        };
        throw error;
    }
}

const rateLimitHeaderPrefix = 'x-ae-ratelimit';

export type RateLimitError = {
    /** in second */
    retryAfter: number;
};

export type RateLimitErrorAdvanced = RateLimitError & {
    consumed: number;
    remaining: number;
    limit: number;
    reset: number;
};

export function getIsRateLimitError(input: any): input is RateLimitError {
    return typeof input === 'object' && input !== null && 'retryAfter' in input;
}

export function getIsRateLimitErrorAdvanced(input: any): input is RateLimitErrorAdvanced {
    return (
        getIsRateLimitError(input) &&
        'consumed' in input &&
        'remaining' in input &&
        'limit' in input &&
        'reset' in input &&
        'retryAfter' in input
    );
}
