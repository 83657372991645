import { useCallback, useRef, useState } from 'react';
import { SerializedError } from '@reduxjs/toolkit';

import { LoadingState } from '@libs/LoadingStatus';

import { ApiError } from '@api/common/utils';

/**
 *
 * @param promise a async function witch must fail if operation fail
 */
export function usePromise<Data, Args extends any[], Error = ApiError>(
    promise: (...args: Args) => Promise<Data>,
    options: {
        onSuccess?: (data: Data) => void;
        onFailure?: (error: Error) => void;
    } = {},
) {
    const [state, setState] = useState<LoadingState<Data>>({ status: 'idle' });
    const lastRequestIdRef = useRef<number | null>(null);

    // allows to reset data (ex: need an empty list state between 2 calls)
    const reset = useCallback(() => setState({ status: 'idle' }), []);

    const startLoading = useCallback(
        async (...args: Args) => {
            const RequestId = lastRequestIdRef.current != null ? lastRequestIdRef.current + 1 : 0;
            lastRequestIdRef.current = RequestId;

            if (state.status !== 'loading') {
                setState((state) => ({ ...state, status: 'loading' }));
            }

            let nextState: LoadingState<Data>;
            try {
                const data = await promise(...args);
                nextState = {
                    ...state,
                    status: 'success',
                    data,
                };
            } catch (e) {
                const error: SerializedError = e;
                nextState = {
                    ...state,
                    status: 'failure',
                    error,
                };
            }
            const noOtherLoadingWasLaunched = RequestId === lastRequestIdRef.current;
            if (noOtherLoadingWasLaunched) {
                setState(nextState);
                if (nextState.status === 'success' && options.onSuccess != null) {
                    options.onSuccess(nextState.data);
                } else if (nextState.status === 'failure' && options.onFailure != null) {
                    options.onFailure(nextState.error);
                }
                return nextState;
            }
        },
        [promise, state],
    );

    return [state, startLoading, reset] as const;
}
