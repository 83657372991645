import { getUserTypeFromUrl } from '@libs/getSharedVar';
import { getJwt } from '@libs/jwt';

import { getAPIBaseUrl } from './utils';

export async function deleteEntity() {
    const side = getUserTypeFromUrl();
    const url = `${getAPIBaseUrl() + side}/${side}s.delete`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });

    return await response;
}
