import './styles/colors.less';
import '../less/app.less';
import '@draft-js-plugins/emoji/lib/plugin.css';
import '@styles/muiGlobal.less';
import 'whatwg-fetch';

import React, { ReactElement, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import setupLocatorUI from '@locator/runtime';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { ErrorMonitoringProvider } from '@providers/ErrorMonitoringProvider';
import { LocalizationProvider } from '@providers/LocalizationProvider';
import NotificationsProvider from '@providers/NotificationsProvider';
import store from '@providers/reduxStore';
import { lightTheme } from '@providers/theme';

import { LoadingView } from '@components/generic/LoadingView';

import { history } from '@libs/getSharedVar';
import { cookies } from '@libs/jwt';

import App from './App';

if (import.meta.env.DEV && import.meta.env.MODE !== 'test') {
    setupLocatorUI();
}

const queryClient = new QueryClient();

function Providers({ children }: { children: ReactElement }) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={lightTheme}>
                {/* The error boundary is after mui provider
                because the error page use mui and if app
                crash, I bet it is not in those theme provider*/}
                <ErrorMonitoringProvider>
                    <QueryClientProvider client={queryClient}>
                        <ReduxProvider store={store}>
                            <Router history={history}>
                                <LocalizationProvider>
                                    <CookiesProvider cookies={cookies}>
                                        <NotificationsProvider>
                                            <Suspense fallback={<LoadingView />}>
                                                {children}
                                            </Suspense>
                                        </NotificationsProvider>
                                    </CookiesProvider>
                                </LocalizationProvider>
                            </Router>
                        </ReduxProvider>
                        <ReactQueryDevtools />
                    </QueryClientProvider>
                </ErrorMonitoringProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

const container = document.getElementById('app')!;
const root = createRoot(container);
root.render(
    <Providers>
        <App />
    </Providers>,
);
