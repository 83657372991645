import {
    ADVERTISER_REPORTING_LOADING,
    CLOSE_DOCKS,
    DELETE_SAVED_REPORT,
    DELETE_SAVED_REPORT_FAILURE,
    DELETE_SAVED_REPORT_SUCCESS,
    FETCH_ADVERTISER_REPORTING_DATA,
    FETCH_ADVERTISER_REPORTING_DATA_FAILURE,
    FETCH_ADVERTISER_REPORTING_DATA_SUCCESS,
    FETCH_SAVED_REPORTS,
    FETCH_SAVED_REPORTS_FAILURE,
    FETCH_SAVED_REPORTS_SUCCESS,
    FETCH_USER_PREFERENCES,
    FETCH_USER_PREFERENCES_FAILURE,
    FETCH_USER_PREFERENCES_SUCCESS,
    OPEN_PROGRAM_SAVED_REPORT_DOCK,
    OPEN_SAVE_REPORT_DOCK,
    SAVE_REPORT,
    SAVE_REPORT_FAILURE,
    SAVE_REPORT_SUCCESS,
    UPDATE_CURRENCIES,
    UPDATE_CURRENT_GRAPH_KPI_ID,
    UPDATE_SELECTED_ADS,
    UPDATE_SELECTED_PARTNERSHIPS,
    UPDATE_SELECTED_RULES,
    UPDATE_USER_PREFERENCES,
    UPDATE_USER_PREFERENCES_FAILURE,
    UPDATE_USER_PREFERENCES_SUCCESS,
    UPDATE_VISIBLE_COLUMNS,
} from '@actions/advertiserReporting';

import { IsoDate } from '@libs/types';

// Initial query is for the 30 last days
const initialFrom = new Date();
initialFrom.setDate(initialFrom.getDate() - 30);
initialFrom.setHours(0, 0, 0, 0);

const initialTo = new Date();
initialTo.setHours(23, 59, 59, 999);

export type ReportingState = {
    advertiser: {
        data: unknown;
        filteredData: unknown;
        loading: boolean;
        error: null | unknown;
        queryCriteria: {
            reportTitle: string;
            program: string;
            programName: string;
            programMode: string;
            currency: string;
            from: IsoDate;
            to: IsoDate;
            timezone: string;
            scale: 'year' | 'month' | 'day' | 'hour';
            kind: 'partnerships' | 'ads' | 'rules'; // can be 'partnerships', 'ads', 'rules'
            /**@deprecated */
            jwt: string;
            /**@deprecated */
            endpoint: string;
            status: 'all' | 'active' | 'deleted';
            group: null | string[];
            partnership: string;
            title: string;
        };
        visibleColumns: string[]; // could be more precise

        selectedPartnerships: Map<string, unknown>;
        selectedAds: Map<string, unknown>;
        selectedRules: Map<string, unknown>;
        selectedProducts: Map<string, unknown>;
        selectedGroups: Map<string, unknown>;

        currentGraphKpiId: string; // could be more precise
        currencies: string[];
        isOpenSaveReportDock: boolean;
        isOpenProgramSavedReportDock: boolean;

        savedReports: unknown[];
    };
};

const INITIAL_STATE: ReportingState = {
    advertiser: {
        data: {},
        filteredData: {},
        loading: false,
        error: null,
        queryCriteria: {
            reportTitle: '',
            program: '',
            programName: '',
            programMode: '',
            currency: '',
            from: initialFrom.toISOString(), // must be ISO date
            to: initialTo.toISOString(), // must be ISO date
            timezone: '',
            scale: 'day',
            kind: 'partnerships',
            jwt: '',
            endpoint: '',
            status: 'all',
            group: null,
            partnership: '',
            title: '',
        },
        visibleColumns: [
            'id',
            'name',
            'preview',
            'impressions',
            'clicks',
            'allContributions',
            'allConversions',
            'globalConversionRate',
            'allCommissions',
            'revenueOnConversions',
        ],

        selectedPartnerships: new Map(),
        selectedAds: new Map(),
        selectedRules: new Map(),
        selectedProducts: new Map(),
        selectedGroups: new Map(),

        currentGraphKpiId: 'events',
        currencies: [],
        isOpenSaveReportDock: false,
        isOpenProgramSavedReportDock: false,

        savedReports: [],
    },
};

export default function (state = INITIAL_STATE, action): ReportingState {
    let error;

    switch (action.type) {
        // ADVERTISER REPORTING
        case ADVERTISER_REPORTING_LOADING:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    queryCriteria: action.queryCriteria,
                    loading: true,
                },
            };
        case FETCH_ADVERTISER_REPORTING_DATA:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    loading: true,
                },
            };
        case FETCH_ADVERTISER_REPORTING_DATA_SUCCESS:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    data: action.payload,
                    queryCriteria: action.queryCriteria,
                    error: error,
                    loading: false,
                },
            };
        case FETCH_ADVERTISER_REPORTING_DATA_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    data: {},
                    error: error,
                    loading: false,
                },
            };
        case FETCH_SAVED_REPORTS:
            return {
                ...state,
            };
        case FETCH_SAVED_REPORTS_SUCCESS:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    savedReports: action.payload,
                },
            };
        case FETCH_SAVED_REPORTS_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    error: error,
                },
            };
        case UPDATE_SELECTED_PARTNERSHIPS:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    selectedPartnerships: action.selectedPartnerships,
                },
            };
        case UPDATE_VISIBLE_COLUMNS:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    visibleColumns: action.visibleColumns,
                },
            };
        case UPDATE_SELECTED_ADS:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    selectedAds: action.selectedAds,
                },
            };
        case UPDATE_SELECTED_RULES:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    selectedRules: action.selectedRules,
                },
            };
        case UPDATE_CURRENT_GRAPH_KPI_ID:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    currentGraphKpiId: action.graphKpiId,
                },
            };
        case UPDATE_CURRENCIES:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    currencies: action.currencies,
                },
            };

        case FETCH_USER_PREFERENCES:
            return {
                ...state,
            };
        case FETCH_USER_PREFERENCES_SUCCESS:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    visibleColumns: action.visibleColumns,
                },
            };
        case FETCH_USER_PREFERENCES_FAILURE:
            return {
                ...state,
            };

        case UPDATE_USER_PREFERENCES:
            return {
                ...state,
            };
        case UPDATE_USER_PREFERENCES_SUCCESS:
            return {
                ...state,
            };
        case UPDATE_USER_PREFERENCES_FAILURE:
            return {
                ...state,
            };

        case SAVE_REPORT:
            return {
                ...state,
            };
        case SAVE_REPORT_SUCCESS:
            return {
                ...state,
            };
        case SAVE_REPORT_FAILURE:
            return {
                ...state,
            };

        case DELETE_SAVED_REPORT:
            return {
                ...state,
            };
        case DELETE_SAVED_REPORT_SUCCESS:
            return {
                ...state,
            };
        case DELETE_SAVED_REPORT_FAILURE:
            return {
                ...state,
            };
        case OPEN_SAVE_REPORT_DOCK:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    isOpenSaveReportDock: true,
                },
            };
        case OPEN_PROGRAM_SAVED_REPORT_DOCK:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    isOpenProgramSavedReportDock: true,
                },
            };
        case CLOSE_DOCKS:
            return {
                ...state,
                advertiser: {
                    ...state.advertiser,
                    isOpenSaveReportDock: false,
                    isOpenProgramSavedReportDock: false,
                },
            };
        default:
            break;
    }

    return state;
}
