import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

import {
    formatMomentForApi,
    getExplicitPeriodFromPeriodRef,
} from '@components/generic/DateRangePicker';

import { getJwt } from '@libs/jwt';

import { getAPISubUrlForAdmin } from '@api/common/utils';

export const UPDATE_PROGRAMS_AM_CRITERIA = 'UPDATE_PROGRAMS_AM_CRITERIA';
export const UPDATE_PROGRAMS_AM_VISIBLE_COLUMNS = 'UPDATE_PROGRAMS_AM_VISIBLE_COLUMNS';

export const FETCH_PROGRAMS_AM_DATA = 'FETCH_PROGRAMS_AM_DATA';
export const FETCH_PROGRAMS_AM_DATA_SUCCESS = 'FETCH_PROGRAMS_AM_DATA_SUCCESS';
export const FETCH_PROGRAMS_AM_DATA_FAILURE = 'FETCH_PROGRAMS_AM_DATA_FAILURE';
export const FETCH_PROGRAMS_AM_DATA_LOADING = 'FETCH_PROGRAMS_AM_DATA_LOADING';

export const UPDATE_PROGRAMS_AM_PROGRAM_DATA = 'UPDATE_PROGRAMS_AM_PROGRAM_DATA';
export const UPDATE_PROGRAMS_AM_PROGRAM_DATA_SUCCESS = 'UPDATE_PROGRAMS_AM_PROGRAM_DATA_SUCCESS';
export const UPDATE_PROGRAMS_AM_PROGRAM_DATA_FAILURE = 'UPDATE_PROGRAMS_AM_PROGRAM_DATA_FAILURE';

export const OPEN_EDIT_AM_DIALOG = 'OPEN_EDIT_AM_DIALOG';
export const OPEN_EDIT_PAYMENT_OPTION_DIALOG = 'OPEN_EDIT_PAYMENT_OPTION_DIALOG';
export const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS';

export const UPDATE_PROGRAM_AM_FIELDS = 'UPDATE_PROGRAM_AM_FIELDS';
export const UPDATE_PROGRAM_PAYMENT_OPTION_FIELDS = 'UPDATE_PROGRAM_PAYMENT_OPTION_FIELDS';

export const UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT = 'UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT';
export const UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_SUCCESS =
    'UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_SUCCESS';
export const UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_FAILURE =
    'UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_FAILURE';

export const UPDATE_PROGRAMS_PAYMENT_OPTION = 'UPDATE_PROGRAMS_PAYMENT_OPTION';
export const UPDATE_PROGRAMS_PAYMENT_OPTION_SUCCESS = 'UPDATE_PROGRAMS_PAYMENT_OPTION_SUCCESS';
export const UPDATE_PROGRAMS_PAYMENT_OPTION_FAILURE = 'UPDATE_PROGRAMS_PAYMENT_OPTION_FAILURE';

function getQueryString(queryCriteria) {
    const { from, to } = getExplicitPeriodFromPeriodRef(queryCriteria.period);
    let qs = '?from=' + formatMomentForApi(from) + '&to=' + formatMomentForApi(to);

    if (!_.isEmpty(queryCriteria.categories)) {
        _.each(queryCriteria.categories, function (category) {
            qs = qs.concat('&categories=' + category);
        });
    }
    if (!_.isEmpty(queryCriteria.countries)) {
        _.each(queryCriteria.countries, function (country) {
            qs = qs.concat('&countries=' + country);
        });
    }
    if (queryCriteria.mainCategory != null && queryCriteria.mainCategory !== '') {
        qs += '&mainCategory=' + queryCriteria.mainCategory;
    }
    if (queryCriteria.verified != null) {
        qs += '&verified=' + queryCriteria.verified;
    }
    if (queryCriteria.marketplace != null) {
        qs += '&marketplace=' + queryCriteria.marketplace;
    }
    if (queryCriteria.am != null) {
        qs += '&am=' + queryCriteria.am;
    }
    if (queryCriteria.paymentOption != null) {
        qs += '&paymentOption=' + queryCriteria.paymentOption;
    }
    if (!_.isEmpty(queryCriteria.subscriptionStatus)) {
        qs += '&subscriptionStatus=' + queryCriteria.subscriptionStatus;
    }
    if (!_.isEmpty(queryCriteria.amManager)) {
        qs += '&amManager=' + queryCriteria.amManager;
    }
    if (!_.isEmpty(queryCriteria.search)) {
        qs += '&search=' + queryCriteria.search;
    }
    return qs;
}

export function updateProgramsAmCriteria(queryCriteria) {
    return {
        type: UPDATE_PROGRAMS_AM_CRITERIA,
        queryCriteria: queryCriteria,
    };
}

export function updateProgramsAmVisibleColumns(visibleColumns) {
    return {
        type: UPDATE_PROGRAMS_AM_VISIBLE_COLUMNS,
        visibleColumns: visibleColumns,
    };
}

export function fetchProgramsAmData(queryCriteria) {
    const qs = getQueryString(queryCriteria);

    const promise = fetch(getAPISubUrlForAdmin() + '/amReports/programs.stats' + qs, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + getJwt() },
    });

    return {
        type: FETCH_PROGRAMS_AM_DATA,
        payload: promise,
    };
}

export function fetchProgramsAmDataSuccess(data) {
    return {
        type: FETCH_PROGRAMS_AM_DATA_SUCCESS,
        payload: data,
    };
}

export function fetchProgramsAmDataFailure(error) {
    return {
        type: FETCH_PROGRAMS_AM_DATA_FAILURE,
        payload: error,
    };
}

export function fetchProgramsAmDataLoading() {
    return {
        type: FETCH_PROGRAMS_AM_DATA_LOADING,
    };
}

export function updateProgramsAmProgramData(programId, verified, featured, marketplace) {
    const bodyObj = {
        program: programId,
    };
    if (verified != null) {
        bodyObj.verified = verified;
    }
    if (featured != null) {
        bodyObj.featured = featured;
    }
    if (marketplace != null) {
        bodyObj.marketplace = marketplace;
    }

    const promise = fetch(getAPISubUrlForAdmin() + '/programs.update', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: UPDATE_PROGRAMS_AM_PROGRAM_DATA,
        payload: promise,
    };
}

export function updateProgramsAmProgramDataSuccess(program, allData) {
    // We deep clone here to avoid mutating the state
    // See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
    const updatedDatas = JSON.parse(JSON.stringify(allData));
    // We update some locally fields (isVerified for example)
    // without having to reload the entire set from API
    updatedDatas[program.id].isVerified = program.isVerified;
    updatedDatas[program.id].isFeatured = program.isFeatured;
    updatedDatas[program.id].allowMarketplace = program.allowMarketplace;
    updatedDatas[program.id].hasPaymentOption = program.hasPaymentOption;

    return {
        type: UPDATE_PROGRAMS_AM_PROGRAM_DATA_SUCCESS,
        payload: updatedDatas,
    };
}

export function updateProgramsAmProgramDataFailure(error) {
    return {
        type: UPDATE_PROGRAMS_AM_PROGRAM_DATA_FAILURE,
        payload: error,
    };
}

export function openEditAmDialog(program) {
    const fields = {
        programId: program.programId,
        programName: program.programName,
        hasAm: program.hasAm,
        amFrom: program.amFrom ? moment.unix(program.amFrom) : null,
        amTo: program.amTo ? moment.unix(program.amTo) : null,
        amManager: program.amManager,
        amTimeSpent: program.amTimeSpent,
        amSubscription: program.amSubscription,
        currency: program.currency,
    };

    return {
        type: OPEN_EDIT_AM_DIALOG,
        fields: fields,
    };
}

export function openEditPaymentOptionDialog(program) {
    const fields = {
        programId: program.programId,
        programName: program.programName,
        hasPaymentOption: program.hasPaymentOption,
        levels: program.paymentOptionLevels,
        currency: program.currency,
    };

    return {
        type: OPEN_EDIT_PAYMENT_OPTION_DIALOG,
        fields: fields,
    };
}

export function closeAllDialogs() {
    return {
        type: CLOSE_ALL_DIALOGS,
    };
}

export function updateProgramAmFields(fields) {
    return {
        type: UPDATE_PROGRAM_AM_FIELDS,
        fields: fields,
    };
}

export function updateProgramPaymentOptionFields(fields) {
    return {
        type: UPDATE_PROGRAM_PAYMENT_OPTION_FIELDS,
        fields: fields,
    };
}

export function updateProgramsAmProgramManagement(fields) {
    const bodyObj = {
        program: fields.programId,
        hasAm: fields.hasAm,
    };
    if (fields.amFrom != null) {
        bodyObj.amFrom = fields.amFrom.unix();
    }
    if (fields.amTo != null) {
        bodyObj.amTo = fields.amTo.unix();
    }
    if (fields.amManager != null) {
        bodyObj.amManager = fields.amManager;
    }
    if (fields.amTimeSpent != null) {
        bodyObj.amTimeSpent = fields.amTimeSpent;
    }
    if (fields.amSubscription != null) {
        bodyObj.amSubscription = fields.amSubscription;
    }

    const promise = fetch(getAPISubUrlForAdmin() + '/am/program.update', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT,
        payload: promise,
    };
}

export function updateProgramsAmProgramManagementSuccess(program, allData) {
    // We deep clone here to avoid mutating the state
    // See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
    const updatedDatas = JSON.parse(JSON.stringify(allData));
    // We update some locally fields (isVerified for example)
    // without having to reload the entire set from API

    // todo
    updatedDatas[program.id].hasAm = program.hasAm;
    updatedDatas[program.id].amFrom = program.amFrom;
    updatedDatas[program.id].amTo = program.amTo;
    updatedDatas[program.id].amManager = program.amManager;
    updatedDatas[program.id].amTimeSpent = program.amTimeSpent;
    updatedDatas[program.id].amSubscription = program.amSubscription;

    return {
        type: UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_SUCCESS,
        payload: updatedDatas,
    };
}

export function updateProgramsAmProgramManagementFailure(error) {
    return {
        type: UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_FAILURE,
        payload: error,
    };
}

export function updateProgramsPaymentOption(fields) {
    const bodyObj = {
        program: fields.programId,
        hasPaymentOption: fields.hasPaymentOption,
    };
    if (!_.isEmpty(fields.levels)) {
        bodyObj.levels = fields.levels;
    }
    const promise = fetch(getAPISubUrlForAdmin() + '/am/option.update', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: UPDATE_PROGRAMS_PAYMENT_OPTION,
        payload: promise,
    };
}

export function updateProgramsPaymentOptionSuccess(program, allData) {
    // We deep clone here to avoid mutating the state
    // See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
    const updatedDatas = JSON.parse(JSON.stringify(allData));
    // We update some locally fields (isVerified for example)
    // without having to reload the entire set from API

    // todo
    updatedDatas[program.id].hasPaymentOption = program.hasPaymentOption;
    updatedDatas[program.id].paymentOptionLevels = program.paymentOptionLevels;

    return {
        type: UPDATE_PROGRAMS_PAYMENT_OPTION_SUCCESS,
        payload: updatedDatas,
    };
}

export function updateProgramsPaymentOptionFailure(error) {
    return {
        type: UPDATE_PROGRAMS_PAYMENT_OPTION_FAILURE,
        payload: error,
    };
}
