import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';

import { useOldAppState } from '@hooks/useOldAppState';

import { BankDetailsApiVerification } from '@api/publisher/paymentRequests/verifyPublisher';

import { useBannerStore } from './useBannerStore';

const MissingBankDetailsWarningBanner = () => {
    const { i18n, t } = useTranslation();
    const locale = i18n.language;
    const bankDetails = useBannerStore((state) => state.bankDetails);

    const oldGlobalState = useOldAppState().state;
    const subEntities = oldGlobalState.affiliateProfiles!;
    const getSubEntityName = (id: string) =>
        subEntities && subEntities.find((subEntity) => subEntity.id === id)?.name;

    const invalidReasons = [
        {
            name: 'Country not allowed',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_country'),
        },
        {
            name: 'Wrong publisher type',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_publisher'),
        },
        {
            name: 'Wrong affiliate type',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_affiliate'),
        },
        {
            name: 'VAT rate not set',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_vat_set'),
        },
        {
            name: 'VAT rate not valid',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_vat_valid'),
        },
    ];

    const invalidReasonsName = invalidReasons.map((reason) => reason.name);
    const invalidReasonsAndMissingBankDetails = [...invalidReasonsName, 'Missing Bank Details'];

    const missingData = [
        {
            name: 'country',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_missing_country'),
        },
        {
            name: 'type',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_missing_type'),
        },
        {
            name: 'address',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_missing_address'),
        },
        {
            name: 'zipcode',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_missing_zipcode'),
        },
        {
            name: 'city',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_missing_city'),
        },
        {
            name: 'bank_account_number',
            label: t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_account_number',
            ),
        },
        {
            name: 'bank_city',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_city'),
        },
        {
            name: 'bank_zip_code',
            label: t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_zip_code',
            ),
        },
        {
            name: 'bank_name',
            label: t('dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_name'),
        },
        {
            name: 'bank_country',
            label: t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_country',
            ),
        },
        {
            name: 'bank_address',
            label: t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_address',
            ),
        },
        {
            name: 'bank_account_owner',
            label: t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_account_owner',
            ),
        },
        {
            name: 'bank_account_number_type',
            label: t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_account_number_type',
            ),
        },
        {
            name: 'bank_identifier_type',
            label: t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_identifier_type',
            ),
        },
    ];

    const wrongAffiliateProfileBankDetails = bankDetails.find(
        (bankDetail) =>
            bankDetail.affiliateProfile &&
            bankDetail.paymentIsAllowed === false &&
            bankDetail.paymentAllowedReasons,
    );

    const wrongPublisherBankDetails = bankDetails.find(
        (bankDetail) => bankDetail.publisher && bankDetail.paymentIsAllowed === false,
    );

    if (wrongAffiliateProfileBankDetails) {
        const affiliateProfileId = wrongAffiliateProfileBankDetails.affiliateProfile!;
        if (
            wrongAffiliateProfileBankDetails.paymentAllowedReasons?.every((reason) =>
                invalidReasonsAndMissingBankDetails.includes(reason.split(',')[0]),
            )
        ) {
            const errorReasons = getErrorReasons(
                t,
                wrongAffiliateProfileBankDetails,
                invalidReasons,
                missingData,
            );
            if (errorReasons.length === 0) {
                return null;
            }
            const redirectionSectionName = getRedirectionSectionName(t, errorReasons);

            return (
                <AlertBanner>
                    <Trans
                        i18nKey='dashboard_publisher_bank_details_banner_incorrect_profile'
                        shouldUnescape
                        values={{
                            profileName: getSubEntityName(affiliateProfileId),
                        }}
                        components={{
                            linkto: (
                                <Link
                                    to={`/${locale}/publisher/${affiliateProfileId}/settings/edit?section=${redirectionSectionName}`}
                                />
                            ),
                            errorReasons: (
                                <>
                                    {errorReasons.map((reason, index) => (
                                        <React.Fragment key={reason}>
                                            {index > 0 && ` ${t('generic_and')} `}
                                            {t(reason)}
                                            {index === errorReasons.length - 1 && '.'}
                                        </React.Fragment>
                                    ))}
                                </>
                            ),
                        }}
                    />
                </AlertBanner>
            );
        }
    } else if (wrongPublisherBankDetails) {
        if (
            wrongPublisherBankDetails.paymentAllowedReasons?.every((reason) =>
                invalidReasonsAndMissingBankDetails.includes(reason.split(',')[0]),
            ) &&
            wrongPublisherBankDetails.missingData.length < 14
        ) {
            const errorReasons = getErrorReasons(
                t,
                wrongPublisherBankDetails,
                invalidReasons,
                missingData,
            );
            if (errorReasons.length === 0) {
                return null;
            }
            const redirectionSectionName = getRedirectionSectionName(t, errorReasons);
            return (
                <AlertBanner>
                    {t('dashboard_publisher_bank_details_banner_incorrect_account_start')}{' '}
                    {errorReasons.map((reason, index) => (
                        <React.Fragment key={reason}>
                            {index > 0 && ` ${t('generic_and')} `}
                            {t(reason)}
                            {index === errorReasons.length - 1 && '.'}
                        </React.Fragment>
                    ))}{' '}
                    <Trans
                        i18nKey='dashboard_publisher_bank_details_banner_incorrect_account_end'
                        components={{
                            linkto: (
                                <Link
                                    to={`/${locale}/publisher/config?section=${redirectionSectionName}`}
                                />
                            ),
                        }}
                    />
                </AlertBanner>
            );
        } else {
            return (
                <AlertBanner>
                    <Trans
                        i18nKey='dashboard_publisher_bank_details_banner_empty_account'
                        components={{
                            linkto: <Link to={`/${locale}/publisher/config`} />,
                        }}
                    />
                </AlertBanner>
            );
        }
    }

    return null;
};

export default MissingBankDetailsWarningBanner;

const AlertBanner = ({ children }: { children: React.ReactNode }) => {
    const { isBannerOpen, setIsBannerOpen } = useBannerStore();
    const user = useOldAppState().state.user!;
    const isAdmin = user.isAffilaeAdmin;
    const closeBanner = () => {
        setIsBannerOpen(false);
    };

    return (
        isBannerOpen === true &&
        isAdmin === false && (
            <Alert
                icon={false}
                severity='warning'
                sx={{
                    borderRadius: '0',
                    '& .MuiAlert-message': {
                        marginLeft: 'auto',
                    },
                }}
                onClose={() => closeBanner()}
            >
                {children}
            </Alert>
        )
    );
};

const getErrorReasons = (
    t: (key: string) => string,
    bankDetails: BankDetailsApiVerification,
    invalidReasons: { name: string; label: string }[],
    missingData?: { name: string; label: string }[],
) => {
    const errorReasons: string[] = [];

    invalidReasons.forEach((reason) => {
        if (bankDetails.paymentAllowedReasons?.includes(reason.name)) {
            errorReasons.push(reason.label);
        }
    });

    // We don't want 'wrong publisher type' to trigger warning banner
    if (
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_publisher'),
        )
    ) {
        errorReasons.splice(
            errorReasons.indexOf(
                t('dashboard_publisher_bank_details_banner_incorrect_account_publisher'),
            ),
            1,
        );
    }
    // Same for 'wrong affiliate type'
    if (
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_affiliate'),
        )
    ) {
        errorReasons.splice(
            errorReasons.indexOf(
                t('dashboard_publisher_bank_details_banner_incorrect_account_affiliate'),
            ),
            1,
        );
    }

    if (!missingData) {
        return errorReasons;
    }

    if (bankDetails.missingData.length <= 3) {
        missingData.forEach((data) => {
            if (bankDetails.missingData.includes(data.name)) {
                errorReasons.push(data.label);
            }
        });
    } else if (bankDetails.missingData.length > 3) {
        errorReasons.push(
            t('dashboard_publisher_bank_details_banner_incorrect_account_missing_data'),
        );
    }

    // Avoid to display 'VAT rate not set' and 'VAT rate not valid' together, as they correspond to the same input error
    if (
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_vat_set'),
        ) &&
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_vat_valid'),
        )
    ) {
        errorReasons.splice(
            errorReasons.indexOf(
                t('dashboard_publisher_bank_details_banner_incorrect_account_vat_set'),
            ),
            1,
        );
    }

    return errorReasons;
};

const getRedirectionSectionName = (t: (key: string) => string, errorReasons: string[]) => {
    let section = 'businessDetails';

    if (
        errorReasons.includes(t('country')) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_missing_type'),
        ) ||
        errorReasons.includes(t('address')) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_missing_zipcode'),
        ) ||
        errorReasons.includes(t('city')) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_country'),
        ) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_publisher'),
        ) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_vat_valid'),
        )
    ) {
        section = 'businessDetails';
    } else if (
        errorReasons.includes(
            t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_account_number',
            ),
        ) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_city'),
        ) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_zip_code'),
        ) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_name'),
        ) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_country'),
        ) ||
        errorReasons.includes(
            t('dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_address'),
        ) ||
        errorReasons.includes(
            t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_account_owner',
            ),
        ) ||
        errorReasons.includes(
            t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_account_number_type',
            ),
        ) ||
        errorReasons.includes(
            t(
                'dashboard_publisher_bank_details_banner_incorrect_account_missing_bank_identifier_type',
            ),
        )
    ) {
        section = 'billingDetails';
    }

    return section;
};
