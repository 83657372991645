import logo from '@assets/images/logo-noir-190x60.png';

import Dialog, { DialogProps } from '@mui/material/Dialog';

import LanguageSelector from '@components/nav/SideBar/LanguageSelector';

export function DialogNotAuth({
    children,
    withLogo = true,
    PaperProps,
    logoProps,
    ...props
}: Omit<DialogProps, 'open' | 'onclose'> & {
    withLogo?: boolean;
    logoProps?: JSX.IntrinsicElements['img'];
}) {
    const { sx: sxPaperProps, ...otherPaperProps } = PaperProps ?? {};
    const { style: styleLogoProps, ...otherLogoProps } = logoProps ?? {};

    return (
        <Dialog
            open={true}
            PaperProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '580px',
                    ...sxPaperProps,
                },
                ...otherPaperProps,
            }}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    },
                },
            }}
            {...props}
        >
            {withLogo && (
                <img
                    src={logo}
                    alt='affilae-logo'
                    style={{
                        marginBottom: '20px',
                        alignSelf: 'center',
                        marginTop: '30px',
                        ...styleLogoProps,
                    }}
                    {...otherLogoProps}
                />
            )}
            <div
                style={{
                    position: 'absolute',
                    top: '44px',
                    left: '32px',
                    border: 'solid 0.2px #80808063',
                    borderRadius: '4px',
                    padding: '0 3px',
                }}
            >
                <LanguageSelector
                    customSx={{
                        color: 'currentColor',
                        fontSize: '1em',
                        '& .MuiSelect-select': { paddingRight: '20px' },
                    }}
                />
            </div>

            {children}
        </Dialog>
    );
}

export function DialogNotAuthTitle({ style, ...props }: JSX.IntrinsicElements['h1']) {
    return (
        <h1
            style={{
                paddingBottom: '1.5rem',
                fontSize: '36px',
                lineHeight: '36px',
                textAlign: 'center',
                ...style,
            }}
            {...props}
        />
    );
}
