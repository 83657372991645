import merge from 'lodash/merge';

import { SxProps, TooltipProps } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Tooltip } from '@components/generic/Tooltip';

type IBubbleHelper = Omit<TooltipProps, 'children'> & {
    customStyles?: { tooltip?: SxProps; icon?: Partial<SxProps> };
};

const BubbleHelper = (props: IBubbleHelper) => {
    const { customStyles } = props;

    const defaultStyles: IBubbleHelper['customStyles'] = {
        tooltip: {
            textAlign: 'justify',
        },
        icon: {
            color: '#545454',
            cursor: 'pointer',
            '&:hover': {
                color: '#75b8e3',
            },
        },
    };

    const styles = merge(defaultStyles, customStyles);

    return (
        <Tooltip sx={styles.tooltip} {...props}>
            <InfoOutlinedIcon style={styles.icon as React.CSSProperties} fontSize='small' />
        </Tooltip>
    );
};

export default BubbleHelper;
