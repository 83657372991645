import './EmailDialogReminder.less';

import mailboxIcon from '@assets/images/mailbox.svg';

import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { hasToken, tokenQueryKey } from '@pages/public/EmailValidationPage';

import LoadingButton from '@mui/lab/LoadingButton';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

import { Tooltip } from '@components/generic/Tooltip';

import { usePromise } from '@hooks/usePromise';

import { emailSupport, getCurrentUser, isBlockedByMail } from '@libs/getSharedVar';

import { sendConfirmEmail } from '@api/user/sendConfirmEmail';

export function EmailDialogReminder() {
    const { t } = useTranslation();
    const user = getCurrentUser();
    const token = new URL(window.location.href).searchParams.get(tokenQueryKey);
    const [isOpen, setIsOpen] = useState(
        user.emailConfirmedAt == null && !hasToken(token) && !isBlockedByMail(user),
    );
    const [loading, handleSend, resetLoading] = usePromise(sendConfirmEmail);

    function handleClose() {
        setIsOpen(false);
        resetLoading();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            className='emailDialogReminder'
            data-testid='emailDialogReminder'
        >
            <div className='iconWrapper'>
                <img src={mailboxIcon} width={200} height={200} />
            </div>
            <DialogTitle>{t('emailDialogReminder_title')}</DialogTitle>

            <IconButton className='closeBtn' onClick={handleClose}>
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <Trans i18nKey='emailDialogReminder_description'>
                    Please confirm your email since we will use it to send your important
                    information <br />
                    If you can't receive the mail or encounter any other problem, we are here to
                    help <a href={`mailto:${emailSupport}`}>contact us</a>
                </Trans>
            </DialogContent>

            <DialogActions>
                <LoadingButton
                    onClick={handleSend}
                    color='primary'
                    variant='contained'
                    disabled={loading.status === 'loading' || loading.status === 'success'}
                    className='submitBtn'
                    loading={loading.status === 'loading'}
                    data-testid='reSendMailBtn'
                    endIcon={
                        (loading.status === 'success' && (
                            <CheckIcon fontSize='inherit' style={{ color: 'green' }} />
                        )) ||
                        (loading.status === 'failure' && (
                            <Tooltip
                                title={
                                    loading.error.message ??
                                    t('messaging_error_view_basic_msg').toString()
                                }
                            >
                                <ErrorIcon color='error' fontSize='small' />
                            </Tooltip>
                        ))
                    }
                >
                    {loading.status !== 'success'
                        ? t('emailValidation_reSendMail')
                        : t('emailValidation_reSendMail_success')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
