/**
 * Contain reusable layout for table page (ex: payment, earning, product, etc..)
 */

import React from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Tooltip } from '@components/generic/Tooltip';

import currencyFormatter from '@libs/lib_currency';

export const headerMarginBottom = '2rem';

type KpiListProps = {
    children: React.ReactNode;
};

export function KpiList({ children }: KpiListProps) {
    return (
        <Paper
            data-testid='kpiList'
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginBottom: {
                    md: headerMarginBottom,
                    xs: '1rem',
                },
            }}
        >
            {children}
        </Paper>
    );
}

export function Kpi({
    amount,
    onClick,
    color,
    currency,
    label,
}: {
    amount?: number;
    label: string;
    onClick?: () => void;
    color: string;
    currency?: string;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
                cursor: amount ? 'pointer' : 'none',
            }}
            style={{ color }}
            onClick={onClick}
        >
            <Box sx={{ fontSize: '27px' }}>
                {amount ? (currency ? currencyFormatter(currency, amount) : amount) : '-'}
                {/* todo format number that are not currency */}
            </Box>
            <Box>{label}</Box>
        </Box>
    );
}

export function LayoutPageTable({
    className,
    title,
    actions,
    kpi,
    filters,
    table,
    dialogs,
    compactTitle,
    classNamePaper,
    classNameFiltersLayout,
}: LayoutHeaderProps & {
    className?: string;
    kpi?: KpiListProps['children'];
    filters?: LayoutFiltersProps['children'];
    table: React.ReactNode;
    dialogs?: React.ReactNode;
    classNamePaper?: string;
    classNameFiltersLayout?: string;
}) {
    return (
        <LayoutPage className={className}>
            <LayoutHeader title={title} actions={actions} compactTitle={compactTitle} />

            {kpi && <KpiList>{kpi}</KpiList>}

            <Paper className={classNamePaper}>
                {filters && (
                    <LayoutFilters
                        sx={{ borderRadius: '1rem 1rem 0 0' }}
                        className={classNameFiltersLayout}
                    >
                        {filters}
                    </LayoutFilters>
                )}
                {table}
            </Paper>

            {dialogs}
        </LayoutPage>
    );
}

type LayoutHeaderProps = {
    /** the action button to display on top right corner of page*/
    actions?: React.ReactNode;
} & (
    | {
          title: string;
          compactTitle?: boolean;
      }
    | {
          title: React.ReactElement;
          compactTitle?: never;
      }
);

export function LayoutHeader({ title, actions, compactTitle = false }: LayoutHeaderProps) {
    return (
        <Box
            component='header'
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: '1rem',
                marginBottom: {
                    md: headerMarginBottom,
                    xs: '1rem',
                },
                minHeight: '4rem', // so header have same weigh if action button are fab or normal btn
            }}
        >
            <Box sx={{ marginLeft: '1rem' }}>
                {typeof title !== 'string' ? (
                    title
                ) : compactTitle ? (
                    <PageTitleCompact>{title}</PageTitleCompact>
                ) : (
                    <PageTitle>{title}</PageTitle>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                }}
            >
                {actions}
            </Box>
        </Box>
    );
}

export function PageTitle({ children }: { children: React.ReactNode }) {
    return (
        <Typography
            variant='h1'
            fontFamily='Lato,sans-serif;'
            fontSize={26}
            fontWeight={300}
            padding={0}
            textAlign='left'
        >
            {children}
        </Typography>
    );
}

// used when page is a tab content (ex: payments & earnings pages)
export function PageTitleCompact({ children }: { children: React.ReactNode }) {
    return (
        <Typography variant='h1' fontSize={21} fontWeight={400} padding={0} textAlign='left'>
            {children}
        </Typography>
    );
}

export function PageSubTitle({ children }: { children: React.ReactNode }) {
    return (
        <Typography
            variant='h2'
            fontSize={16}
            fontWeight={400}
            padding={0}
            textAlign='left'
            color='#666666'
            marginTop={'4px'}
        >
            {children}
        </Typography>
    );
}

type LayoutFiltersProps = {
    children: React.ReactNode;
    className?: string;
    sx?: BoxProps['sx'];
};

export function LayoutFilters({ children, className, sx }: LayoutFiltersProps) {
    return (
        <Box
            data-testid='layoutFilters'
            sx={{
                padding: '1rem',
                display: 'flex',
                alignItems: 'flex-end',
                flexWrap: 'wrap',
                background: '#dfeff9',
                gap: '1.5rem',
                borderRadius: '1rem 1rem 0 0',
                ...(sx ?? {}),
            }}
            className={className}
        >
            {children}
        </Box>
    );
}

export function LayoutPage({ children, sx, ...otherProps }: BoxProps) {
    return (
        <Box
            sx={{
                padding: {
                    md: '2rem 3rem',
                    xs: '1rem',
                },
                ...sx,
            }}
            {...otherProps}
        >
            {children}
        </Box>
    );
}

export function ActionButtonCompact({
    tooltip,
    tooltipProps,
    icon,
    sx,
    ...otherProps
}: {
    tooltip?: string;
    tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
    icon: JSX.Element;
} & ButtonProps) {
    const size = '36px';

    return (
        <Tooltip title={tooltip ?? ''} {...(tooltipProps ?? {})}>
            <Button
                color='inherit'
                sx={{
                    width: size,
                    minWidth: size,
                    height: size,
                    minHeight: size,
                    padding: 0,
                    backgroundColor:
                        otherProps['disabled'] === true ? 'rgb(229, 229, 229)' : '#ffffff',
                    borderRadius: 0,
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        svg: {
                            fill: '#000000',
                        },
                    },
                    svg: {
                        fill: '#666666',
                        transition: 'all 0.2s ease',
                        fontSize: '18px',
                    },
                    ...(sx ?? {}),
                }}
                {...otherProps}
            >
                {icon}
            </Button>
        </Tooltip>
    );
}
